import { BookingPeriod } from '@spiaggeit/spit-datepicker'

export const HALF_DAY_QUERY_PARAM = 'hd'

export function getHalfDayValueFromBookingPeriod(
  bookingPeriod: BookingPeriod | null
): 0 | 1 | 2 {
  switch (bookingPeriod) {
    case BookingPeriod.ALL_SEASON:
    case BookingPeriod.ALL_DAY:
      return 0
    case BookingPeriod.MORNING:
      return 1
    case BookingPeriod.AFTERNOON:
      return 2
    default:
      return 0
  }
}

export function getBookingPeriodFromHalfDay(value: number): BookingPeriod {
  if (Number.isNaN(value)) {
    return BookingPeriod.ALL_DAY
  }

  switch (value) {
    case 0:
      return BookingPeriod.ALL_DAY
    case 1:
      return BookingPeriod.MORNING
    case 2:
      return BookingPeriod.AFTERNOON
    default:
      return BookingPeriod.ALL_DAY
  }
}

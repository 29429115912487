export const Profile = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg
    fill="currentColor"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 16.0001C18.2089 16.0001 20 14.2091 20 12.0001C20 9.7911 18.2089 8.00008 16 8.00008C13.791 8.00008 12 9.7911 12 12.0001C12 14.2091 13.791 16.0001 16 16.0001Z"
      fill="white"
    />
    <path
      clipRule="evenodd"
      d="M24.6487 26.1482C27.5156 23.7026 29.3333 20.0636 29.3333 16.0001C29.3333 8.63615 23.3639 2.66675 16 2.66675C8.63603 2.66675 2.66663 8.63615 2.66663 16.0001C2.66663 20.0636 4.48433 23.7026 7.3512 26.1482C9.67965 28.1345 12.6998 29.3334 16 29.3334C19.3001 29.3334 22.3203 28.1345 24.6487 26.1482ZM23.7737 23.1257C25.5328 21.3083 26.6666 18.8588 26.6666 16.0001C26.6666 10.1091 21.8909 5.33341 16 5.33341C10.109 5.33341 5.33329 10.1091 5.33329 16.0001C5.33329 18.7947 6.40784 21.338 8.1663 23.24C9.55693 20.5362 12.542 18.6667 16 18.6667C19.4091 18.6667 22.3587 20.4838 23.7737 23.1257Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
)

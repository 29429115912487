import Cookies from 'js-cookie'
import capitalize from 'lodash/capitalize'
import { useEffect } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import {
  CHOOSE_PRODUCT_PATH,
  INSERT_PERIOD_PATH,
  TICKETS_PATH,
} from '../../app/router/paths'
import { useAppSelector } from '../../hooks/store'
import { appSlice } from '../../store/appSlice'
import { licenseSlice } from '../../store/licenseSlice'

export const DataLayer = () => {
  const mode = useAppSelector(appSlice.selectors.mode)
  const license = useAppSelector(licenseSlice.selectors.license)
  const location = useLocation()

  useEffect(() => {
    window.dataLayer.push({ Loggato: Cookies.get('anm22-w-l') ? 'Si' : 'No' })
  }, [])

  useEffect(() => {
    if (!license) return
    window.dataLayer.push({
      Regione: license.license.slice(3, 5).toUpperCase(),
    })
  }, [license])

  useEffect(() => {
    window.dataLayer.push({ Dominio: capitalize(mode) })
  }, [mode])

  useEffect(() => {
    if (!license) return

    if (matchPath(INSERT_PERIOD_PATH, location.pathname)) {
      window.dataLayer.push({
        'Trackpage view': `/stabilimenti-balneari/${license.license}/prenotazione/selezione-date`,
      })
    } else if (matchPath(CHOOSE_PRODUCT_PATH, location.pathname)) {
      window.dataLayer.push({
        'Trackpage view': `/stabilimenti-balneari/${license.license}/prenotazione/selezione-prodotto`,
      })
    } else if (matchPath(TICKETS_PATH, location.pathname)) {
      window.dataLayer.push({
        'Trackpage view': `/stabilimenti-balneari/${license.license}/prenotazione/tickets`,
      })
    }
  }, [location.pathname, license])

  return null
}

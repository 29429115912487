import { Button, TextField } from '@spiaggeit/spit-ui'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Logo } from '../../assets/icons/Logo'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { AppMode } from '../../models/app'
import { appSlice } from '../../store/appSlice'
import {
  getKioskLocalSettings,
  kioskLocalSettingsSchema,
} from '../../utils/kiosk'

export function SettingsRoute() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const mode = useAppSelector(appSlice.selectors.mode)
  const navigate = useNavigate()

  const [license, setLicense] = useState('')
  const [kioskId, setKioskId] = useState('')

  const [errors, setErrors] = useState({ kioskId: false, license: false })

  // initialize app
  useEffect(() => {
    dispatch(appSlice.actions.setupByUrl(window.location.href))
  }, [dispatch])

  useEffect(() => {
    try {
      // if the cookie is already present, redirect to landing page
      const kioskLocalSettings = getKioskLocalSettings()
      if (kioskLocalSettings) {
        navigate(`/${kioskLocalSettings.license}/landingKiosk`)
      }
    } catch {
      // NOOP
    }
  }, [])

  if (mode !== AppMode.KIOSK) {
    return null
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const result = kioskLocalSettingsSchema.safeParse({
      kioskId,
      license,
    })

    if (result.success) {
      Cookies.set(
        import.meta.env.VITE_KIOSK_SETTINGS_COOKIE,
        JSON.stringify(result.data)
      )
      navigate(`/${result.data.license}/landingKiosk`)
      return
    }

    const { fieldErrors } = result.error.flatten()

    setErrors({
      kioskId: !!fieldErrors.kioskId,
      license: !!fieldErrors.license,
    })
  }

  return (
    <div className="mx-auto flex h-full max-w-96 flex-col items-center justify-center space-y-4 p-4">
      <Logo className="max-w-80 text-yellow-200" fill="currentColor" />

      <p>{t('kiosk.settings.message')}</p>

      <form className="w-full space-y-2" onSubmit={onSubmit}>
        <TextField
          errorMsg={errors.license ? t('kiosk.settings.requiredField') : null}
          id="license"
          label={t('kiosk.settings.license')}
          name="license"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setLicense(e.target.value)
          }}
          value={license}
        />

        <TextField
          errorMsg={errors.kioskId ? t('kiosk.settings.requiredField') : null}
          id="kioskID"
          label={t('kiosk.settings.kioskId')}
          name="kioskID"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setKioskId(e.target.value)
          }}
          value={kioskId}
        />

        <Button fullWidth>{t('kiosk.settings.save')}</Button>
      </form>
    </div>
  )
}

import Cookies from 'js-cookie'
import { z } from 'zod'

export const kioskLocalSettingsSchema = z
  .object({
    kioskId: z.string().trim()
      .min(1),
    license: z.string().trim()
      .min(1),
  })
  .required()

export function getKioskLocalSettings(): {
  kioskId: string
  license: string
} | null {
  const cookie = Cookies.get(import.meta.env.VITE_KIOSK_SETTINGS_COOKIE)

  if (typeof cookie !== 'string') {
    return null
  }

  const parsedCookie = kioskLocalSettingsSchema.safeParse(JSON.parse(cookie))

  if (!parsedCookie.success) {
    return null
  }

  return parsedCookie.data
}

import './setupSentry'
import './global.css'
import './app/i18n'
import * as Sentry from '@sentry/react'
import { ToastProvider } from '@spiaggeit/spit-ui'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

// @ts-expect-error - This is a workaround to import the printer_includes_es6 file
import * as printerIncludes from '../../world/libs/anm22_printers/js/printer_includes_es6'
window.androidAppEnable = true

import { Router } from './app/router'
import { store } from './store/configureStore'

Object.assign(window, printerIncludes)

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<>An error has occurred</>}>
      <Suspense fallback={<div>Loading...</div>}>
        <Provider store={store}>
          <ToastProvider>
            <Router />
          </ToastProvider>
        </Provider>
      </Suspense>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
)

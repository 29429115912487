export const ArrowLeft = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg
    fill="currentColor"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M22.6659 14.6667H12.5459L16.9459 10.28C17.1969 10.0289 17.338 9.6884 17.338 9.33333C17.338 8.97826 17.1969 8.63773 16.9459 8.38666C16.6948 8.13559 16.3543 7.99454 15.9992 7.99454C15.6441 7.99454 15.3036 8.13559 15.0525 8.38666L8.38588 15.0533C8.26449 15.1801 8.16934 15.3297 8.10588 15.4933C7.97252 15.8179 7.97252 16.182 8.10588 16.5067C8.16934 16.6703 8.26449 16.8199 8.38588 16.9467L15.0525 23.6133C15.1765 23.7383 15.324 23.8375 15.4864 23.9052C15.6489 23.9729 15.8232 24.0077 15.9992 24.0077C16.1752 24.0077 16.3495 23.9729 16.512 23.9052C16.6745 23.8375 16.8219 23.7383 16.9459 23.6133C17.0708 23.4894 17.17 23.3419 17.2377 23.1794C17.3054 23.0169 17.3403 22.8427 17.3403 22.6667C17.3403 22.4906 17.3054 22.3164 17.2377 22.1539C17.17 21.9914 17.0708 21.8439 16.9459 21.72L12.5459 17.3333H22.6659C23.0195 17.3333 23.3586 17.1929 23.6087 16.9428C23.8587 16.6928 23.9992 16.3536 23.9992 16C23.9992 15.6464 23.8587 15.3072 23.6087 15.0572C23.3586 14.8071 23.0195 14.6667 22.6659 14.6667Z"></path>
  </svg>
)

export const PT = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3839_22306)">
      <mask
        height="24"
        id="mask0_3839_22306"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="24"
        x="0"
        y="0"
      >
        <rect fill="white" height="24" width="24" />
      </mask>
      <g mask="url(#mask0_3839_22306)">
        <path
          clipRule="evenodd"
          d="M6 0H24V24H6V0Z"
          fill="#EF0000"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M0 0H9V24H0V0Z"
          fill="#2F8D00"
          fillRule="evenodd"
        />
        <mask
          height="16"
          id="mask1_3839_22306"
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'luminance' }}
          width="12"
          x="3"
          y="4"
        >
          <path
            clipRule="evenodd"
            d="M8.92878 19.8073C5.73342 19.8073 3.14307 16.3119 3.14307 12.0001C3.14307 7.68829 5.73342 4.19287 8.92878 4.19287C12.1241 4.19287 14.7145 7.68829 14.7145 12.0001C14.7145 16.3119 12.1241 19.8073 8.92878 19.8073Z"
            fill="white"
            fillRule="evenodd"
          />
        </mask>
        <g mask="url(#mask1_3839_22306)">
          <path
            clipRule="evenodd"
            d="M8.57178 4H9.32178V6.69879H8.57178V4Z"
            fill="#FFE017"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M8.57178 17.4941H9.32178V20.0002H8.57178V17.4941Z"
            fill="#FFE017"
            fillRule="evenodd"
          />
          <path
            d="M3.23605 12.4038L3.02637 11.6865C5.34026 10.4548 7.08668 9.6605 8.27167 9.30252C10.0103 8.77732 11.9206 8.38873 14.0027 8.13647L14.0538 8.90447C11.9956 9.15383 10.1097 9.53743 8.39651 10.055C7.24679 10.4023 5.52463 11.1856 3.23605 12.4038Z"
            fill="#FFE017"
          />
          <path
            d="M14.3558 7.66409L14.1856 8.40019C12.4368 7.66408 11.0122 7.34766 9.91721 7.44567L9.87939 6.67627C11.0551 6.57104 12.5454 6.90207 14.3558 7.66409Z"
            fill="#FFE017"
          />
          <path
            d="M14.9612 11.7798L14.6379 12.4157C13.7464 11.5904 12.6481 11.1759 11.3354 11.1759V10.4048C12.7585 10.4048 13.9696 10.8619 14.9612 11.7798Z"
            fill="#FFE017"
          />
          <path
            d="M14.4767 12.0107L14.6244 12.7556C12.7025 13.4497 10.898 13.8436 9.21092 13.9363C7.5122 14.0297 5.5323 13.6345 3.26953 12.7536L3.42792 12.0127C5.63569 12.8722 7.55617 13.2555 9.18766 13.1659C10.8308 13.0755 12.5938 12.6908 14.4767 12.0107Z"
            fill="#FFE017"
          />
          <path
            d="M14.2589 15.636L13.3557 16.8707C11.6391 14.5841 10.1279 12.9491 8.83137 11.9668C7.51264 10.9676 5.79289 9.95302 3.6748 8.92712L4.10916 7.29419C6.28972 8.35034 8.07272 9.40224 9.46084 10.454C10.8711 11.5225 12.4674 13.2495 14.2589 15.636Z"
            fill="#FFE017"
          />
          <path
            d="M4.09385 16.3354L3.75195 15.7176C3.7997 15.6695 3.88489 15.5893 4.00226 15.4876C4.19606 15.3197 4.41127 15.1521 4.64281 14.9955C5.28054 14.5642 5.92024 14.2949 6.53038 14.2701C6.56022 14.2689 6.58993 14.2683 6.61952 14.2683V15.0394C6.59568 15.0394 6.57169 15.0399 6.54754 15.0409C6.03337 15.0617 5.46871 15.2994 4.89887 15.6849C4.68647 15.8285 4.48856 15.9826 4.31095 16.1365C4.24913 16.19 4.19443 16.2396 4.14756 16.2837C4.12023 16.3094 4.10209 16.3271 4.09385 16.3354Z"
            fill="#FFE017"
          />
          <path
            d="M4.35938 16.6028L4.50328 15.8566C6.40837 16.5255 7.87708 16.8589 8.9021 16.8589C9.92328 16.8589 11.4432 16.4657 13.4521 15.6765L13.6118 16.4169C11.5554 17.2247 9.98884 17.6299 8.9021 17.6299C7.81919 17.6299 6.30739 17.2868 4.35938 16.6028Z"
            fill="#FFE017"
          />
          <path
            d="M3.61719 9.69458L4.52249 8.46265L5.84799 10.2363L4.94268 11.4682L3.61719 9.69458Z"
            fill="#FFE017"
          />
          <path
            d="M11.127 15.8478L11.8003 14.3699L13.9031 16.1143L13.2297 17.5923L11.127 15.8478Z"
            fill="#FFE017"
          />
        </g>
        <path
          clipRule="evenodd"
          d="M3.14307 12.0001C3.14307 16.3119 5.73342 19.8073 8.92878 19.8073C12.1241 19.8073 14.7145 16.3119 14.7145 12.0001C14.7145 7.68829 12.1241 4.19287 8.92878 4.19287C5.73342 4.19287 3.14307 7.68829 3.14307 12.0001ZM14.0002 12.0965C14.0002 15.8227 11.7616 18.8435 9.00021 18.8435C6.23879 18.8435 4.00021 15.8227 4.00021 12.0965C4.00021 8.37023 6.23879 5.3495 9.00021 5.3495C11.7616 5.3495 14.0002 8.37023 14.0002 12.0965Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          d="M5.74268 7.1853V13.446C6.05178 15.9241 7.09312 17.1631 8.86672 17.1631C10.6403 17.1631 11.6817 15.9241 11.9908 13.446V7.1853H5.74268Z"
          fill="white"
        />
        <path
          d="M5.31396 6.60693H12.4192V13.4943L12.4132 13.5419C12.07 16.2936 10.8532 17.7414 8.86658 17.7414C6.87991 17.7414 5.66314 16.2936 5.31991 13.5419L5.31396 13.4943V6.60693Z"
          fill="#C51918"
        />
        <path
          d="M6.93896 8.71069H10.7938V13.1516C10.7938 13.1516 10.5077 15.4449 8.86636 15.4449C7.22502 15.4449 6.93896 13.1516 6.93896 13.1516V8.71069Z"
          fill="#F7FCFF"
        />
        <path
          clipRule="evenodd"
          d="M6 7.08423H6.54217V7.56616H6V7.08423Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M5.71436 7.2771H6.71436V7.51806H5.71436V7.2771Z"
          fill="#060101"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M5.71436 7.46973H6.25652V7.71069H5.71436V7.46973Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6.28564 7.46973H6.82781V7.71069H6.28564V7.46973Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M5.71436 8.0481H6.0758V8.77099H5.71436V8.0481Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6.42871 8.0481H6.79016V8.77099H6.42871V8.0481Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6.14307 8.0481H6.32379V8.77099H6.14307V8.0481Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M5.71436 7.85522H6.71436V8.09619H5.71436V7.85522Z"
          fill="#060101"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M5.71436 8.43359H6.71436V8.67456H5.71436V8.43359Z"
          fill="#060101"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M5.85742 7.6626H6.03814V7.90356H5.85742V7.6626Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6.42871 7.6626H6.60943V7.90356H6.42871V7.6626Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6.14307 7.46973H6.32379V7.95165H6.14307V7.46973Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M8.71436 7.08423H9.25652V7.56616H8.71436V7.08423Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M8.42871 7.2771H9.42871V7.51806H8.42871V7.2771Z"
          fill="#060101"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M8.42871 7.46973H8.97088V7.71069H8.42871V7.46973Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M9 7.46973H9.54217V7.71069H9V7.46973Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M8.42871 8.0481H8.79016V8.77099H8.42871V8.0481Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M9.14307 8.0481H9.50451V8.77099H9.14307V8.0481Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M8.85742 8.0481H9.03814V8.77099H8.85742V8.0481Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M8.42871 7.85522H9.42871V8.09619H8.42871V7.85522Z"
          fill="#060101"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M8.42871 8.43359H9.42871V8.67456H8.42871V8.43359Z"
          fill="#060101"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M8.57178 7.6626H8.7525V7.90356H8.57178V7.6626Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M9.14307 7.6626H9.32379V7.90356H9.14307V7.6626Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M8.85742 7.46973H9.03814V7.95165H8.85742V7.46973Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11.2856 7.08423H11.8278V7.56616H11.2856V7.08423Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11 7.2771H12V7.51806H11V7.2771Z"
          fill="#060101"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11 7.46973H11.5422V7.71069H11V7.46973Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11.5713 7.46973H12.1135V7.71069H11.5713V7.46973Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11 8.0481H11.3614V8.77099H11V8.0481Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11.7144 8.0481H12.0758V8.77099H11.7144V8.0481Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11.4287 8.0481H11.6094V8.77099H11.4287V8.0481Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11 7.85522H12V8.09619H11V7.85522Z"
          fill="#060101"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11 8.43359H12V8.67456H11V8.43359Z"
          fill="#060101"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11.1431 7.6626H11.3238V7.90356H11.1431V7.6626Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11.7144 7.6626H11.8951V7.90356H11.7144V7.6626Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11.4287 7.46973H11.6094V7.95165H11.4287V7.46973Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11.2856 10.5542H11.8278V11.0361H11.2856V10.5542Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11 10.7471H12V10.988H11V10.7471Z"
          fill="#060101"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11 10.9397H11.5422V11.1807H11V10.9397Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11.5713 10.9397H12.1135V11.1807H11.5713V10.9397Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11 11.5181H11.3614V12.241H11V11.5181Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11.7144 11.5181H12.0758V12.241H11.7144V11.5181Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11.4287 11.5181H11.6094V12.241H11.4287V11.5181Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11 11.3252H12V11.5662H11V11.3252Z"
          fill="#060101"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11 11.9036H12V12.1445H11V11.9036Z"
          fill="#060101"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11.1431 11.1326H11.3238V11.3735H11.1431V11.1326Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11.7144 11.1326H11.8951V11.3735H11.7144V11.1326Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11.4287 10.9397H11.6094V11.4216H11.4287V10.9397Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6 10.5542H6.54217V11.0361H6V10.5542Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M5.71436 10.7471H6.71436V10.988H5.71436V10.7471Z"
          fill="#060101"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M5.71436 10.9397H6.25652V11.1807H5.71436V10.9397Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6.28564 10.9397H6.82781V11.1807H6.28564V10.9397Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M5.71436 11.5181H6.0758V12.241H5.71436V11.5181Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6.42871 11.5181H6.79016V12.241H6.42871V11.5181Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6.14307 11.5181H6.32379V12.241H6.14307V11.5181Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M5.71436 11.3252H6.71436V11.5662H5.71436V11.3252Z"
          fill="#060101"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M5.71436 11.9036H6.71436V12.1445H5.71436V11.9036Z"
          fill="#060101"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M5.85742 11.1326H6.03814V11.3735H5.85742V11.1326Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6.42871 11.1326H6.60943V11.3735H6.42871V11.1326Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6.14307 10.9397H6.32379V11.4216H6.14307V10.9397Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6.34766 14.9038L6.69616 14.35L6.97304 14.6598L6.62454 15.2136L6.34766 14.9038Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6.2749 15.3196L6.91769 14.2982L7.05613 14.4531L6.41334 15.4745L6.2749 15.3196Z"
          fill="#060101"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6.38574 15.4434L6.73424 14.8896L6.87268 15.0445L6.52418 15.5982L6.38574 15.4434Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6.75293 14.8599L7.10143 14.3061L7.23987 14.461L6.89137 15.0148L6.75293 14.8599Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6.71777 15.8152L6.95011 15.446L7.36543 15.9107L7.1331 16.2799L6.71777 15.8152Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M7.17676 15.0857L7.40909 14.7165L7.82442 15.1812L7.59208 15.5504L7.17676 15.0857Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6.99316 15.3774L7.10933 15.1929L7.52466 15.6575L7.40849 15.8421L6.99316 15.3774Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6.60693 15.6912L7.24972 14.6698L7.38816 14.8247L6.74538 15.8461L6.60693 15.6912Z"
          fill="#060101"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6.93945 16.063L7.58224 15.0416L7.72068 15.1965L7.07789 16.2179L6.93945 16.063Z"
          fill="#060101"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6.58838 15.4214L6.70455 15.2368L6.84299 15.3917L6.72682 15.5763L6.58838 15.4214Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6.95557 14.8379L7.07173 14.6533L7.21018 14.8082L7.09401 14.9928L6.95557 14.8379Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6.66113 15.0056L6.7773 14.821L7.05418 15.1308L6.93802 15.3154L6.66113 15.0056Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11.2339 14.4661L11.5824 15.0198L11.3055 15.3296L10.957 14.7758L11.2339 14.4661Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M10.9399 14.2983L11.5827 15.3197L11.4443 15.4746L10.8015 14.4532L10.9399 14.2983Z"
          fill="#060101"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M10.8291 14.4221L11.1776 14.9759L11.0392 15.1308L10.6907 14.577L10.8291 14.4221Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11.1963 15.0059L11.5448 15.5596L11.4063 15.7145L11.0578 15.1607L11.1963 15.0059Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M10.4966 14.7939L10.7289 15.1631L10.3136 15.6278L10.0813 15.2586L10.4966 14.7939Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M10.9561 15.5234L11.1884 15.8926L10.7731 16.3573L10.5407 15.9881L10.9561 15.5234Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M10.772 15.2317L10.8881 15.4163L10.4728 15.8809L10.3566 15.6964L10.772 15.2317Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M10.6074 14.6699L11.2502 15.6913L11.1118 15.8462L10.469 14.8248L10.6074 14.6699Z"
          fill="#060101"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M10.2754 15.0417L10.9182 16.0631L10.7797 16.218L10.1369 15.1966L10.2754 15.0417Z"
          fill="#060101"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M10.8101 14.6919L10.9262 14.8765L10.7878 15.0314L10.6716 14.8468L10.8101 14.6919Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11.1772 15.2756L11.2934 15.4602L11.155 15.6151L11.0388 15.4305L11.1772 15.2756Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11.1045 14.8599L11.2207 15.0445L10.9438 15.3542L10.8276 15.1696L11.1045 14.8599Z"
          fill="#F9E813"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M9.41896 9.20483H8.42871V10.1663C8.42871 10.1663 8.50219 10.6628 8.92384 10.6628C9.34548 10.6628 9.41896 10.1663 9.41896 10.1663V9.20483ZM8.85728 9.59038C8.85728 9.69684 8.79332 9.78315 8.71443 9.78315C8.63553 9.78315 8.57157 9.69684 8.57157 9.59038C8.57157 9.48391 8.63553 9.3976 8.71443 9.3976C8.79332 9.3976 8.85728 9.48391 8.85728 9.59038ZM8.71443 10.5542C8.79332 10.5542 8.85728 10.4679 8.85728 10.3615C8.85728 10.255 8.79332 10.1687 8.71443 10.1687C8.63553 10.1687 8.57157 10.255 8.57157 10.3615C8.57157 10.4679 8.63553 10.5542 8.71443 10.5542ZM9.28585 10.3615C9.28585 10.4679 9.2219 10.5542 9.143 10.5542C9.0641 10.5542 9.00014 10.4679 9.00014 10.3615C9.00014 10.255 9.0641 10.1687 9.143 10.1687C9.2219 10.1687 9.28585 10.255 9.28585 10.3615ZM8.92871 10.1687C9.00761 10.1687 9.07157 10.0824 9.07157 9.97592C9.07157 9.86945 9.00761 9.78315 8.92871 9.78315C8.84981 9.78315 8.78585 9.86945 8.78585 9.97592C8.78585 10.0824 8.84981 10.1687 8.92871 10.1687ZM9.28585 9.59038C9.28585 9.69684 9.2219 9.78315 9.143 9.78315C9.0641 9.78315 9.00014 9.69684 9.00014 9.59038C9.00014 9.48391 9.0641 9.3976 9.143 9.3976C9.2219 9.3976 9.28585 9.48391 9.28585 9.59038Z"
          fill="#013399"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M9.41896 11.3252H8.42871V12.2867C8.42871 12.2867 8.50219 12.7832 8.92384 12.7832C9.34548 12.7832 9.41896 12.2867 9.41896 12.2867V11.3252ZM8.85728 11.7107C8.85728 11.8172 8.79332 11.9035 8.71443 11.9035C8.63553 11.9035 8.57157 11.8172 8.57157 11.7107C8.57157 11.6043 8.63553 11.518 8.71443 11.518C8.79332 11.518 8.85728 11.6043 8.85728 11.7107ZM8.71443 12.6746C8.79332 12.6746 8.85728 12.5883 8.85728 12.4818C8.85728 12.3754 8.79332 12.2891 8.71443 12.2891C8.63553 12.2891 8.57157 12.3754 8.57157 12.4818C8.57157 12.5883 8.63553 12.6746 8.71443 12.6746ZM9.28585 12.4818C9.28585 12.5883 9.2219 12.6746 9.143 12.6746C9.0641 12.6746 9.00014 12.5883 9.00014 12.4818C9.00014 12.3754 9.0641 12.2891 9.143 12.2891C9.2219 12.2891 9.28585 12.3754 9.28585 12.4818ZM8.92871 12.2891C9.00761 12.2891 9.07157 12.2027 9.07157 12.0963C9.07157 11.9898 9.00761 11.9035 8.92871 11.9035C8.84981 11.9035 8.78585 11.9898 8.78585 12.0963C8.78585 12.2027 8.84981 12.2891 8.92871 12.2891ZM9.28585 11.7107C9.28585 11.8172 9.2219 11.9035 9.143 11.9035C9.0641 11.9035 9.00014 11.8172 9.00014 11.7107C9.00014 11.6043 9.0641 11.518 9.143 11.518C9.2219 11.518 9.28585 11.6043 9.28585 11.7107Z"
          fill="#013399"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M10.7046 11.3252H9.71436V12.2867C9.71436 12.2867 9.78784 12.7832 10.2095 12.7832C10.6311 12.7832 10.7046 12.2867 10.7046 12.2867V11.3252ZM10.1429 11.7107C10.1429 11.8172 10.079 11.9035 10.0001 11.9035C9.92117 11.9035 9.85721 11.8172 9.85721 11.7107C9.85721 11.6043 9.92117 11.518 10.0001 11.518C10.079 11.518 10.1429 11.6043 10.1429 11.7107ZM10.0001 12.6746C10.079 12.6746 10.1429 12.5883 10.1429 12.4818C10.1429 12.3754 10.079 12.2891 10.0001 12.2891C9.92117 12.2891 9.85721 12.3754 9.85721 12.4818C9.85721 12.5883 9.92117 12.6746 10.0001 12.6746ZM10.5715 12.4818C10.5715 12.5883 10.5075 12.6746 10.4286 12.6746C10.3497 12.6746 10.2858 12.5883 10.2858 12.4818C10.2858 12.3754 10.3497 12.2891 10.4286 12.2891C10.5075 12.2891 10.5715 12.3754 10.5715 12.4818ZM10.2144 12.2891C10.2933 12.2891 10.3572 12.2027 10.3572 12.0963C10.3572 11.9898 10.2933 11.9035 10.2144 11.9035C10.1355 11.9035 10.0715 11.9898 10.0715 12.0963C10.0715 12.2027 10.1355 12.2891 10.2144 12.2891ZM10.5715 11.7107C10.5715 11.8172 10.5075 11.9035 10.4286 11.9035C10.3497 11.9035 10.2858 11.8172 10.2858 11.7107C10.2858 11.6043 10.3497 11.518 10.4286 11.518C10.5075 11.518 10.5715 11.6043 10.5715 11.7107Z"
          fill="#013399"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M8.13332 11.3252H7.14307V12.2867C7.14307 12.2867 7.21655 12.7832 7.63819 12.7832C8.05983 12.7832 8.13332 12.2867 8.13332 12.2867V11.3252ZM7.57164 11.7107C7.57164 11.8172 7.50768 11.9035 7.42878 11.9035C7.34988 11.9035 7.28592 11.8172 7.28592 11.7107C7.28592 11.6043 7.34988 11.518 7.42878 11.518C7.50768 11.518 7.57164 11.6043 7.57164 11.7107ZM7.42878 12.6746C7.50768 12.6746 7.57164 12.5883 7.57164 12.4818C7.57164 12.3754 7.50768 12.2891 7.42878 12.2891C7.34988 12.2891 7.28592 12.3754 7.28592 12.4818C7.28592 12.5883 7.34988 12.6746 7.42878 12.6746ZM8.00021 12.4818C8.00021 12.5883 7.93625 12.6746 7.85735 12.6746C7.77845 12.6746 7.71449 12.5883 7.71449 12.4818C7.71449 12.3754 7.77845 12.2891 7.85735 12.2891C7.93625 12.2891 8.00021 12.3754 8.00021 12.4818ZM7.64307 12.2891C7.72196 12.2891 7.78592 12.2027 7.78592 12.0963C7.78592 11.9898 7.72196 11.9035 7.64307 11.9035C7.56417 11.9035 7.50021 11.9898 7.50021 12.0963C7.50021 12.2027 7.56417 12.2891 7.64307 12.2891ZM8.00021 11.7107C8.00021 11.8172 7.93625 11.9035 7.85735 11.9035C7.77845 11.9035 7.71449 11.8172 7.71449 11.7107C7.71449 11.6043 7.77845 11.518 7.85735 11.518C7.93625 11.518 8.00021 11.6043 8.00021 11.7107Z"
          fill="#013399"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M9.41896 13.4458H8.42871V14.4073C8.42871 14.4073 8.50219 14.9038 8.92384 14.9038C9.34548 14.9038 9.41896 14.4073 9.41896 14.4073V13.4458ZM8.85728 13.8313C8.85728 13.9378 8.79332 14.0241 8.71443 14.0241C8.63553 14.0241 8.57157 13.9378 8.57157 13.8313C8.57157 13.7249 8.63553 13.6386 8.71443 13.6386C8.79332 13.6386 8.85728 13.7249 8.85728 13.8313ZM8.71443 14.7952C8.79332 14.7952 8.85728 14.7089 8.85728 14.6024C8.85728 14.496 8.79332 14.4097 8.71443 14.4097C8.63553 14.4097 8.57157 14.496 8.57157 14.6024C8.57157 14.7089 8.63553 14.7952 8.71443 14.7952ZM9.28585 14.6024C9.28585 14.7089 9.2219 14.7952 9.143 14.7952C9.0641 14.7952 9.00014 14.7089 9.00014 14.6024C9.00014 14.496 9.0641 14.4097 9.143 14.4097C9.2219 14.4097 9.28585 14.496 9.28585 14.6024ZM8.92871 14.4097C9.00761 14.4097 9.07157 14.3233 9.07157 14.2169C9.07157 14.1104 9.00761 14.0241 8.92871 14.0241C8.84981 14.0241 8.78585 14.1104 8.78585 14.2169C8.78585 14.3233 8.84981 14.4097 8.92871 14.4097ZM9.28585 13.8313C9.28585 13.9378 9.2219 14.0241 9.143 14.0241C9.0641 14.0241 9.00014 13.9378 9.00014 13.8313C9.00014 13.7249 9.0641 13.6386 9.143 13.6386C9.2219 13.6386 9.28585 13.7249 9.28585 13.8313Z"
          fill="#013399"
          fillRule="evenodd"
        />
      </g>
    </g>
    <rect
      fill="url(#paint0_linear_3839_22306)"
      height="24"
      rx="12"
      style={{ mixBlendMode: 'overlay' }}
      width="24"
    />
    <rect
      height="23"
      rx="11.5"
      stroke="black"
      strokeOpacity="0.1"
      width="23"
      x="0.5"
      y="0.5"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_3839_22306"
        x1="12"
        x2="12"
        y1="0"
        y2="24"
      >
        <stop stopColor="white" stopOpacity="0.7" />
        <stop offset="1" stopOpacity="0.3" />
      </linearGradient>
      <clipPath id="clip0_3839_22306">
        <rect fill="white" height="24" rx="12" width="24" />
      </clipPath>
    </defs>
  </svg>
)

import * as rd from '@devexperts/remote-data-ts'

import { httpClient } from '../lib/http/HttpClient'
import { AppMode } from '../models/app'
import {
  BookingAvailabilityError,
  BookingAvailabilityResponse,
  BookingAvailabilityResponseError,
} from '../models/bookingAvailability'
import { getHalfDayValueFromBookingPeriod } from '../utils/halfDay'

import { appSlice } from './appSlice'
import { RootState } from './configureStore'
import { createAppSlice } from './createAppSlice'
import {
  insertPeriodSlice,
  periodAsUnixTimeSelector,
} from './insertPeriodSlice'
import { licenseSlice } from './licenseSlice'

export enum BookingFlow {
  SPOTS = 0,
  TICKETS = 1,
}

export enum BookingAvailability {
  NONE = 'none',
  SPOTS = 'spots',
  TICKETS = 'tickets',
  SPOTS_AND_TICKETS = 'spots-and-tickets',
}

type SliceState = rd.RemoteData<
  {
    code: BookingAvailabilityError
    substitutions: Record<string, string>
  },
  | {
      availableSpots: number
      type: BookingAvailability.SPOTS
    }
  | {
      availableTickets: number
      type: BookingAvailability.TICKETS
    }
  | {
      availableTickets: number
      availableSpots: number
      type: BookingAvailability.SPOTS_AND_TICKETS
    }
  | {
      type: BookingAvailability.NONE
    }
>

const initialState: SliceState = rd.initial

export const bookingAvailabilitySlice = createAppSlice({
  initialState: initialState satisfies SliceState as SliceState,
  name: 'bookingAvailability',
  reducers: (create) => ({
    load: create.asyncThunk<
      {
        appMode: AppMode
        bookingFlow?: BookingFlow
        data: BookingAvailabilityResponse
      },
      { bookingFlow?: BookingFlow },
      {
        rejectValue: {
          code: BookingAvailabilityError
          substitutions?: Record<string, string>
        }
      }
    >(
      async (
        payload,
        thunkApi
      ): Promise<{
        appMode: AppMode
        bookingFlow?: BookingFlow
        data: BookingAvailabilityResponse
      }> => {
        const rootState = thunkApi.getState() as RootState
        const license = licenseSlice.selectors.license(rootState)
        const period = periodAsUnixTimeSelector(rootState)
        const appMode = appSlice.selectors.mode(rootState)
        const country = appSlice.selectors.country(rootState)
        const bookingPeriod =
          insertPeriodSlice.selectors.bookingPeriod(rootState)

        if (!license) {
          throw thunkApi.rejectWithValue({
            code: BookingAvailabilityError.LICENSE_NOT_FOUND,
          })
        }

        if (!period) {
          throw thunkApi.rejectWithValue({
            code: BookingAvailabilityError.PERIOD_NOT_FOUND,
          })
        }

        const res = await httpClient.fetch<
          BookingAvailabilityResponse,
          BookingAvailabilityResponseError
        >(`beaches/${license.license}/booking-availability`, country, {
          body: JSON.stringify({
            from: period.start,
            halfDay: getHalfDayValueFromBookingPeriod(bookingPeriod),
            to: period.end,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
        })

        if (res.status === 'error') {
          throw thunkApi.rejectWithValue({
            code:
              res.error.internal_error_code ??
              BookingAvailabilityError.GENERIC_ERROR,
            substitutions: res.error.error_ex?.substitution ?? {},
          })
        }

        return {
          appMode,
          bookingFlow: payload.bookingFlow,
          data: res.data,
        }
      },
      {
        fulfilled: (_state, action) => {
          const data = action.payload.data
          const result = data.result
          const availableSpots = result.mapAvailability.hasMapLimit
            ? result.mapAvailability.availableSpots
            : Infinity
          const availableTickets = result.serviceAvailability.hasServiceLimit
            ? result.serviceAvailability.availableTickets
            : Infinity

          if (
            result.mapAvailability.hasSpots &&
            result.serviceAvailability.hasServices
          ) {
            if (
              action.payload.appMode === AppMode.WEBSITE &&
              action.payload.bookingFlow === BookingFlow.SPOTS
            ) {
              return rd.success({
                availableSpots,
                type: BookingAvailability.SPOTS,
              })
            } else if (
              action.payload.appMode === AppMode.WEBSITE &&
              action.payload.bookingFlow === BookingFlow.TICKETS
            ) {
              return rd.success({
                availableTickets,
                type: BookingAvailability.TICKETS,
              })
            } else {
              return rd.success({
                availableSpots,
                availableTickets,
                type: BookingAvailability.SPOTS_AND_TICKETS,
              })
            }
          } else if (result.mapAvailability.hasSpots) {
            return rd.success({
              availableSpots,
              type: BookingAvailability.SPOTS,
            })
          } else if (result.serviceAvailability.hasServices) {
            return rd.success({
              availableTickets,
              type: BookingAvailability.TICKETS,
            })
          } else {
            return rd.failure({
              code: BookingAvailabilityError.SOLD_OUT,
              substitutions: {},
            })
          }
        },
        pending: () => rd.pending,
        rejected: (_, action) => {
          if (!action.payload) {
            return rd.failure({
              code: BookingAvailabilityError.GENERIC_ERROR,
              substitutions: {},
            })
          }
          return rd.failure({
            code: action.payload.code,
            substitutions: action.payload.substitutions ?? {},
          })
        },
      }
    ),
  }),
  selectors: {
    areSpotsAvailable: (state) =>
      rd.isSuccess(state) &&
      (state.value.type === BookingAvailability.SPOTS ||
        state.value.type === BookingAvailability.SPOTS_AND_TICKETS) &&
      state.value.availableSpots > 0,
    areTicketsAvailable: (state) =>
      rd.isSuccess(state) &&
      (state.value.type === BookingAvailability.TICKETS ||
        state.value.type === BookingAvailability.SPOTS_AND_TICKETS) &&
      state.value.availableTickets > 0,
    errorCode: (state) => (rd.isFailure(state) ? state.error.code : null),
    errorSubstitutions: (state) =>
      rd.isFailure(state) ? state.error.substitutions : null,
    self: (state) => state,
  },
})

import { createSelector } from '@reduxjs/toolkit'

import { cartSlice } from '../store/cartSlice'
import { servicesSlice } from '../store/servicesSlice'

export const servicesWithCartQuantitySelector = createSelector(
  [servicesSlice.selectors.data, cartSlice.selectors.items],
  (services, items) =>
    services?.serviceGroups
      .map((group) => ({
        ...group,
        services: group.services
          .map((service) => ({
            ...service,
            quantity: items[service.serviceId]?.quantity ?? 0,
          }))
          .filter((service) => service.quantity > 0),
      }))
      .filter((group) =>
        group.services.some((service) => service.quantity > 0)
      ) ?? []
)

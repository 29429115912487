import { Button, cn } from '@spiaggeit/spit-ui'
import { useTranslation } from 'react-i18next'

interface Props {
  className?: string
  isLoading: boolean
}

export const SubmitButton = (props: Props) => {
  const { t } = useTranslation()

  return (
    <Button
      className={cn('relative', props.className)}
      color="primary"
      type="submit"
    >
      <span className={props.isLoading ? 'opacity-0' : ''}>
        {t('common.proceed')}
      </span>

      <div className="absolute inset-x-0 inset-y-0 flex items-center justify-center">
        <div
          className={cn(
            'h-6 w-6 animate-spin rounded-circle border-4 border-white/25 border-t-white',
            {
              'opacity-0': !props.isLoading,
            }
          )}
        />
      </div>
    </Button>
  )
}

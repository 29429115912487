import { Link } from 'react-router-dom'

import { ArrowLeft } from '../../assets/icons/ArrowLeft'
import { Logo } from '../../assets/icons/Logo'
import { LogoFr } from '../../assets/icons/LogoFr'
import { Profile } from '../../assets/icons/Profile'
import { useAppSelector } from '../../hooks/store'
import { useBackLink } from '../../hooks/useBackLink'
import { AppMode, Country } from '../../models/app'
import { appSlice } from '../../store/appSlice'

const HOME_PATH: Record<Country, string> = {
  [Country.IT]: 'https://www.spiagge.it',
  [Country.FR]: 'https://www.plages.fr',
}

export const TopBar = () => {
  const mode = useAppSelector(appSlice.selectors.mode)
  const country = useAppSelector(appSlice.selectors.country)
  const backLink = useBackLink()

  if (mode !== AppMode.WEBSITE) return null

  return (
    <div className="bg-yellow-200">
      <div className="mx-auto flex max-w-screen-xl items-center justify-between px-4 py-4">
        <div className="flex items-center space-x-2">
          {backLink
            ? (
                <Link to={backLink}>
                  <ArrowLeft className="h-8 w-8 text-white" />
                </Link>
              )
            : null}

          <a href={HOME_PATH[country]}>
            {country === Country.IT ? <Logo className="h-8 w-auto" /> : null}
            {country === Country.FR ? <LogoFr className="h-8 w-auto" /> : null}
          </a>
        </div>

        <a href="/profilo/">
          <Profile className="h-8 w-8 text-white" />
        </a>
      </div>
    </div>
  )
}

import { DE } from './DE'
import { EN } from './EN'
import { ES } from './ES'
import { FR } from './FR'
import { IT } from './IT'
import { PT } from './PT'

export const Flags = {
  DE,
  EN,
  ES,
  FR,
  IT,
  PT,
}

export const IT = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3839_21840)">
      <mask
        height="24"
        id="mask0_3839_21840"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="24"
        x="0"
        y="0"
      >
        <rect fill="white" height="24" width="24" />
      </mask>
      <g mask="url(#mask0_3839_21840)">
        <path
          clipRule="evenodd"
          d="M16.5 0H24V24H16.5V0Z"
          fill="#C51918"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M0 0H9V24H0V0Z"
          fill="#5EAA22"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M7.5 0H16.5V24H7.5V0Z"
          fill="white"
          fillRule="evenodd"
        />
      </g>
    </g>
    <rect
      fill="url(#paint0_linear_3839_21840)"
      height="24"
      rx="12"
      style={{ mixBlendMode: 'overlay' }}
      width="24"
    />
    <rect
      height="23"
      rx="11.5"
      stroke="black"
      strokeOpacity="0.1"
      width="23"
      x="0.5"
      y="0.5"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_3839_21840"
        x1="12"
        x2="12"
        y1="0"
        y2="24"
      >
        <stop stopColor="white" stopOpacity="0.7" />
        <stop offset="1" stopOpacity="0.3" />
      </linearGradient>
      <clipPath id="clip0_3839_21840">
        <rect fill="white" height="24" rx="12" width="24" />
      </clipPath>
    </defs>
  </svg>
)

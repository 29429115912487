import { cn } from '@spiaggeit/spit-ui'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import { Info } from '../../assets/icons/Info'
import { useAppSelector } from '../../hooks/store'
import { cartSlice, cartTotalSelector } from '../../store/cartSlice'
import { insertPeriodSlice } from '../../store/insertPeriodSlice'
import { licenseSlice } from '../../store/licenseSlice'
import { priceFormatter } from '../../utils/price'
import { servicesWithCartQuantitySelector } from '../../utils/services'

const SECTION_CLASSNAME = 'p-4 space-y-1'

interface Props {
  openFeesDialog: VoidFunction
}

export const Sidebar = (props: Props) => {
  const { i18n, t } = useTranslation()
  const cartTotal = useAppSelector(cartTotalSelector)
  const cartFees = useAppSelector(cartSlice.selectors.fees)
  const servicesWithCartQuantity = useAppSelector(
    servicesWithCartQuantitySelector
  )
  const license = useAppSelector(licenseSlice.selectors.license)
  const beachLocation = useAppSelector(licenseSlice.selectors.beachLocation)
  const period = useAppSelector(insertPeriodSlice.selectors.period)

  if (!license || !period) return

  const dateFormat = 'dd MMM'
  const startDate = DateTime.fromISO(period.start).toFormat(dateFormat, {
    locale: i18n.language,
  })
  const endDate =
    period.end !== period.start
      ? DateTime.fromISO(period.end).toFormat(dateFormat, {
        locale: i18n.language,
      })
      : null
  const dateString = endDate ? `${startDate} - ${endDate}` : startDate

  return (
    <div className="hidden md:col-span-4 md:col-start-9 md:block">
      <div className="sticky top-24">
        <h4 className="mb-2 text-lg font-bold">{t('tickets.summary.title')}</h4>

        <div className="divide-y divide-gray-50 rounded border border-gray-50 bg-gray-25">
          <div className={SECTION_CLASSNAME}>
            <div className="font-bold">{license.name}</div>

            <div className="text-sm text-secondary">{beachLocation}</div>

            <div className="text-sm text-secondary">{dateString}</div>
          </div>

          {servicesWithCartQuantity.map((group) => (
            <div
              className={cn(SECTION_CLASSNAME, 'bg-white')}
              key={group.serviceGroupId}
            >
              <div className="font-bold">{group.name}</div>

              {group.services.map((item) => (
                <div
                  className="flex justify-between text-sm text-secondary"
                  key={item.serviceId}
                >
                  <div className="flex items-center space-x-2">
                    <div>
                      <img
                        alt={item.name}
                        className="h-4 w-4"
                        src={`/icons/${item.icon}`}
                      />
                    </div>
                    <div>
                      {item.quantity} x {item.name}
                    </div>
                  </div>

                  <div>{priceFormatter.format(item.price * item.quantity)}</div>
                </div>
              ))}
            </div>
          ))}

          <div className={SECTION_CLASSNAME}>
            <div className="flex justify-between text-sm text-secondary">
              <div>{t('tickets.summary.ticket')}</div>

              <div>{priceFormatter.format(cartTotal - cartFees)}</div>
            </div>

            <div className="flex justify-between text-sm text-secondary">
              <div
                className="flex cursor-pointer space-x-1"
                onClick={props.openFeesDialog}
              >
                <div className="underline">{t('tickets.summary.fees')}</div>

                <Info />
              </div>

              <div>{priceFormatter.format(cartFees)}</div>
            </div>

            <div className="flex justify-between font-bold">
              <div>{t('tickets.summary.total')}</div>

              <div>{priceFormatter.format(cartTotal)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

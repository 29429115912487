import { LockDates } from '@spiaggeit/spit-datepicker'
import { DateTime } from 'luxon'

import { License } from '../models/license'

type LicenseData = Pick<
  License,
  'bookingToday' | 'bookingTimeLimit' | 'bookingEndPeriodOffset'
>

type GetLockDatesParam = {
  now?: DateTime
  license: LicenseData
  seasonStartDate: DateTime
  seasonEndDate: DateTime
  zone: string
}

function getMaxDate({
  license,
  start,
  seasonEndDate,
}: {
  license: LicenseData
  start: DateTime
  seasonEndDate: DateTime<true>
}): DateTime<true> {
  const bookingEndPeriodOffset = license.bookingEndPeriodOffset ?? 0

  // check setting bookingEndPeriodOffset
  // if greater than 0, calc the last reservable date
  if (bookingEndPeriodOffset > 0) {
    const offsetDay = start
      .plus({ days: bookingEndPeriodOffset })
      .startOf('day')
    return seasonEndDate > offsetDay ? offsetDay : seasonEndDate
  }

  return seasonEndDate
}

export function getLockDates(param: GetLockDatesParam): LockDates | null {
  const { seasonStartDate, seasonEndDate, zone } = param

  if (!seasonStartDate.isValid || !seasonEndDate.isValid) {
    return null
  }

  const now = param.now ?? DateTime.local({ zone })

  const maxDate = getMaxDate({
    license: param.license,
    seasonEndDate,
    start: now,
  })

  // check if season is not started yet
  if (now < seasonStartDate) {
    return {
      maxDate: maxDate.toJSDate(),
      minDate: seasonStartDate.startOf('day').toJSDate(),
    }
  }

  const tomorrow = now.plus({ days: 1 }).startOf('day')

  // check if in the middle of the season
  if (now >= seasonStartDate && now <= maxDate) {
    if (param.license.bookingToday) {
      // if bookingToday is enabled, check if is before the limit
      const timeLimit = param.license.bookingTimeLimit
        ? param.license.bookingTimeLimit
        : '16:00'

      const dateTimeLimit = DateTime.fromISO(
        `${now.toISODate()}T${timeLimit}`,
        {
          zone,
        }
      )

      if (dateTimeLimit.isValid && now < dateTimeLimit) {
        // can book today
        return {
          maxDate: maxDate.toJSDate(),
          minDate: now.startOf('day').toJSDate(),
        }
      }
    }

    return {
      maxDate: maxDate.toJSDate(),
      minDate: tomorrow.toJSDate(),
    }
  }

  return null
}

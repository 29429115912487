export const KioskTicket = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg
    fill="none"
    height="80"
    width="80"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="#F6F8FA" height="80" rx="40" width="80" />
    <path
      d="M34 36a2 2 0 0 0-2 2v4a2 2 0 0 0 4 0v-4a2 2 0 0 0-2-2Zm24 2a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2H22a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2 2 2 0 0 1 0 4 2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h36a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2 2 2 0 0 1 0-4Zm-2-3.64a6 6 0 0 0 0 11.28V50H36a2 2 0 0 0-4 0h-8v-4.36a6 6 0 0 0 0-11.28V30h8a2 2 0 0 0 4 0h20v4.36Z"
      fill="#FFC100"
    />
  </svg>
)

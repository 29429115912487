import { httpClient } from '../lib/http/HttpClient'
import { SectorRuleResponse } from '../models/http'
import { SectorRule } from '../models/insertPeriod'

import { appSlice } from './appSlice'
import { RootState } from './configureStore'
import { createAppSlice } from './createAppSlice'

type SectorRulesSliceState =
  | {
      status: 'success'
      rules: SectorRule[]
    }
  | { status: 'idle' | 'loading' }
  | {
      error: string
      status: 'error'
    }

const initialState: SectorRulesSliceState = {
  status: 'idle',
}

export const sectorRulesSlice = createAppSlice({
  initialState:
    initialState satisfies SectorRulesSliceState as SectorRulesSliceState,
  name: 'sectorRules',
  reducers: (create) => ({
    load: create.asyncThunk<
      SectorRuleResponse,
      string,
      { rejectValue: string }
    >(
      async (license, thunkApi): Promise<SectorRuleResponse> => {
        const rootState = thunkApi.getState() as RootState
        const country = appSlice.selectors.country(rootState)

        const res = await httpClient.fetch<SectorRuleResponse>(
          `beaches/${license}/sector-rules`,
          country
        )

        if (res.status === 'error') {
          throw thunkApi.rejectWithValue(res.error)
        }

        return res.data
      },
      {
        fulfilled: (_, action) => {
          return {
            rules: (action.payload.result?.sectorRules ?? []).map((item) => ({
              requiresParameter: item.ruleParameter !== null,
              ruleParameter: item.ruleParameter,
              validationField: item.validationField,
            })),
            status: 'success',
          }
        },
        pending: () => {
          return {
            status: 'loading',
          }
        },
        rejected: (_, action) => {
          return {
            error: action.payload ?? 'Unknown error',
            status: 'error',
          }
        },
      }
    ),
  }),
  selectors: {
    rules: (state) => (state.status === 'success' ? state.rules : null),
  },
})

import { createAppSlice } from './createAppSlice'

type SliceState = {
  message?: string
  isOpen: boolean
}

const initialState: SliceState = {
  isOpen: false,
}

export const toastSlice = createAppSlice({
  initialState: initialState satisfies SliceState as SliceState,
  name: 'toast',
  reducers: (create) => ({
    setIsOpen: create.reducer<boolean>((state, action) => ({
      ...state,
      isOpen: action.payload,
    })),
    setMessage: create.reducer<string>((state, action) => ({
      ...state,
      message: action.payload,
    })),
    show: create.reducer<string>((_, action) => ({
      isOpen: true,
      message: action.payload,
    })),
  }),
  selectors: {
    isOpen: (state) => state.isOpen,
    message: (state) => state.message,
  },
})

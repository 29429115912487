import { Button, Dialog } from '@spiaggeit/spit-ui'
import { Trans, useTranslation } from 'react-i18next'

import { PaymentError } from '.'

interface Props {
  error: PaymentError | null
  isOpen: boolean
  onPaymentRetry: VoidFunction
  onPaymentCancel: VoidFunction
  onPrintRetry: VoidFunction
  onPrintCancel: VoidFunction
  regId: string
}

export const PaymentKioskDialog = (props: Props) => {
  const { t } = useTranslation()

  if (props.error === 'payment') {
    return (
      <Dialog
        isOpen={props.isOpen}
        mobileMode="default"
        setIsOpen={() => null}
        trigger={null}
      >
        <div className="p-4 text-center">
          <p className="mb-px font-bold">
            {t('kiosk.payment.errorDialog.title')}
          </p>

          <p className="mb-6 text-center text-sm">
            <Trans i18nKey={t('kiosk.payment.errorDialog.message')} />
          </p>

          <div className="flex justify-between space-x-4">
            <Button
              color="secondary"
              fullWidth
              onClick={props.onPaymentCancel}
              size="sm"
              variant="outline"
            >
              {t('kiosk.payment.errorDialog.cancel')}
            </Button>

            <Button fullWidth onClick={props.onPaymentRetry} size="sm">
              {t('kiosk.payment.errorDialog.retry')}
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }

  if (props.error === 'print') {
    return (
      <Dialog
        isOpen={props.isOpen}
        mobileMode="default"
        setIsOpen={() => null}
        trigger={null}
      >
        <div className="p-4 text-center">
          <p className="mb-px font-bold">
            {t('kiosk.payment.printErrorDialog.title')}
          </p>

          <p className="mb-6 text-center text-sm">
            <Trans
              i18nKey={t('kiosk.payment.printErrorDialog.message')}
              values={{ cartId: props.regId }}
            />
          </p>

          <div className="flex justify-between space-x-4">
            <Button
              color="secondary"
              fullWidth
              onClick={props.onPrintCancel}
              size="sm"
              variant="outline"
            >
              {t('kiosk.payment.printErrorDialog.cancel')}
            </Button>

            <Button fullWidth onClick={props.onPrintRetry} size="sm">
              {t('kiosk.payment.printErrorDialog.retry')}
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={props.isOpen}
      mobileMode="default"
      setIsOpen={() => null}
      trigger={null}
    >
      <div className="p-4 text-center">
        <p className="mb-px font-bold">
          {t('kiosk.payment.printDialog.title')}
        </p>

        <p className="mb-6 text-center text-sm">
          <Trans i18nKey={t('kiosk.payment.printDialog.message')} />
        </p>

        <img
          alt="loader"
          className="mx-auto h-10 w-10 animate-spin"
          src="/images/kiosk/loader.png"
        />
      </div>
    </Dialog>
  )
}

import { useSelector } from 'react-redux'

import { INSERT_PERIOD_PATH, TICKETS_PATH } from '../app/router/paths'
import { useCurrentPath } from '../hooks/useCurrentPath'
import { AppMode } from '../models/app'
import { appSlice } from '../store/appSlice'

export const Footer = () => {
  const mode = useSelector(appSlice.selectors.mode)
  const { currentPath } = useCurrentPath()

  /**
   * TODO: this logic could be moved within store
   * ui {
   *   footer: boolean
   * }
   **/
  if (
    mode !== AppMode.WEBSITE ||
    [INSERT_PERIOD_PATH, TICKETS_PATH].includes(currentPath)
  )
    return null

  return (
    <footer className="bg-gray-900 px-4 py-8 text-center text-xs text-gray-50">
      <p className="mb-4">
        Spiagge Srl - Sede legale: Via Marecchiese 48, 47923 Rimini (RN), IT -
        capitale sociale Euro 20408,00 - Iscritta al registro delle imprese di
        Rimini
        <br />
        Sede operativa: Viale della Repubblica 96A, 47923 Rimini (RN), IT - +39
        0541 772375 - info@spiagge.it - p.i./c.f. 04536640404
      </p>
      © 2024 Spiagge Srl. Tutti i diritti riservati.
    </footer>
  )
}

import { Dialog } from '@spiaggeit/spit-ui'
import { useTranslation, Trans } from 'react-i18next'

interface Props {
  isOpen: boolean
  open: (isOpen: boolean) => void
}

export const FeesDialog = (props: Props) => {
  const { t } = useTranslation()

  return (
    <Dialog
      isOpen={props.isOpen}
      setIsOpen={props.open}
      title={t('dialog.fees.title')}
      trigger={null}
    >
      <div className="space-y-2 p-4">
        <Trans i18nKey="dialog.fees.description" />
      </div>
    </Dialog>
  )
}

export enum BookingAvailabilityError {
  GENERIC_ERROR = 'GENERIC_ERROR',
  LICENSE_NOT_FOUND = 'LICENSE_NOT_FOUND',
  PERIOD_NOT_FOUND = 'PERIOD_NOT_FOUND',
  SOLD_OUT = 'SOLD_OUT',
  BOOKING_AVAILABILITY_MANAGER_EXCEPTION_ERROR_001 = 'BOOKING_AVAILABILITY_MANAGER_EXCEPTION_ERROR_001',
  BOOKING_AVAILABILITY_MANAGER_EXCEPTION_ERROR_002 = 'BOOKING_AVAILABILITY_MANAGER_EXCEPTION_ERROR_002',
  BOOKING_AVAILABILITY_MANAGER_EXCEPTION_ERROR_003 = 'BOOKING_AVAILABILITY_MANAGER_EXCEPTION_ERROR_003',
  BOOKING_AVAILABILITY_MANAGER_EXCEPTION_ERROR_004 = 'BOOKING_AVAILABILITY_MANAGER_EXCEPTION_ERROR_004',
  BOOKING_AVAILABILITY_MANAGER_EXCEPTION_ERROR_005 = 'BOOKING_AVAILABILITY_MANAGER_EXCEPTION_ERROR_005',
  BOOKING_AVAILABILITY_MANAGER_EXCEPTION_ERROR_006 = 'BOOKING_AVAILABILITY_MANAGER_EXCEPTION_ERROR_006',
  TICKET_OVERBOOKING_EXCEPTION_ERROR_001 = 'TICKET_OVERBOOKING_EXCEPTION_ERROR_001',
}

export interface BookingAvailabilityResponse {
  result: {
    mapAvailability: {
      availableSpots: number
      hasMapLimit: boolean
      hasSpots: boolean
      totalSpots: number
    }
    serviceAvailability: {
      availableTickets: number
      hasServiceLimit: boolean
      hasServices: boolean
      totalTickets: number
    }
  }
}

export interface BookingAvailabilityResponseError {
  internal_error_code?: BookingAvailabilityError | null
  error_ex?: {
    substitution?: Record<string, string> | null
  } | null
}

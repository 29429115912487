import { useAppSelector } from '../../../hooks/store'
import { AppMode } from '../../../models/app'
import { appSlice } from '../../../store/appSlice'

import { KioskTimeoutDialogContent } from './Content'

export interface Props {
  onKill?: () => Promise<unknown>
  timeout: number
}

export const KioskTimeoutDialog = (props: Props) => {
  const appMode = useAppSelector(appSlice.selectors.mode)

  if (appMode !== AppMode.KIOSK) {
    return null
  }

  return <KioskTimeoutDialogContent {...props} />
}

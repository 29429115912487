export const LogoFr = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg
    version="1.1"
    viewBox="0 0 691.8 126.5"
    x="0px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
    {...props}
  >
    <g>
      <path
        d="M99.1,89.6l-20.3,21.3c-0.5,0.6-0.8,1.4-0.6,2.1c0.1,0.7,0.6,1.4,1.3,1.7c7.9,3.7,17.2,1.9,23.3-4.4
      c4.4-4.6,6.4-11,5.4-17.3C105,92.1,102,91,99.1,89.6z"
        fill="#FFFFFF"
      />
      <path
        d="M124.6,94.9v23.6c0,1.1,0.6,2,1.5,2.7c1.1,0.7,2.5,0.9,3.7,0.4c7.4-3.1,12.1-10.3,12.1-18.5
      c0-3.7-1-7.4-2.9-10.5C134.3,93.9,129.5,94.7,124.6,94.9z"
        fill="#FFFFFF"
      />
      <path
        d="M158.9,58.5c-4.4,1-9,1.6-13.6,1.7c-8.8,0.3-17.5-1.1-25.8-4c-2.3,2.6-4.5,5-6.6,7.5l-0.2,0.3
      c-3.5,3.9-6.8,7.7-9.3,10.5c-2,2.2-4,4.2-6,6c6.5,4.2,13.9,6.8,21.4,7.4c18.3,1.5,35.5-7.4,44.9-23.1c0.8-1.4,0.8-3.1-0.2-4.5
      C162.5,59.1,160.9,58,158.9,58.5z"
        fill="#FFFFFF"
      />
      <path
        d="M66.8,29.6c4-8.7,9.5-16.1,15.8-21.4c-2.2-0.8-4.4-1.5-6.4-2.1c-16.4-4.9-23.4,4.7-26.1,10.8
      c-6.5,14.7-16.7,22-20.9,24.5c0.1,2.6,0.7,7.2,3.6,10.3c1.9,2.1,4.6,3.3,8.1,3.6l0.6-0.1C42.1,55.2,56.3,52.7,66.8,29.6z"
        fill="#FFFFFF"
      />
      <path
        d="M98.2,69.7c2.5-2.7,5.6-6.2,9.2-10.3l0.1-0.1c2.7-3.1,6.1-6.9,9.4-10.6c-0.7-1.8-1-3.6-1-5.5
      c0-8.3,6.8-15.1,15.1-15.1c1.4,0,2.8,0.2,4.1,0.6c-0.4-4.1-2.6-13.5-13.5-19.5c-7.3-4-15.3-4.7-23.1-2.1C88,10.6,79,19.6,73.1,32.5
      C63.3,54,50,60,44.6,61.6c-0.2,0.9-0.4,2.3-0.1,3.8c0.1,0.7,3.2,16.4,19.5,19.5C76.3,87.2,86.2,82.8,98.2,69.7z"
        fill="#FFFFFF"
      />
      <path
        d="M155.2,34.2c-5,0-9.1,4.1-9.1,9.1c0,5,4.1,9.1,9.1,9.1s9.1-4.1,9.1-9.1C164.3,38.3,160.2,34.2,155.2,34.2z"
        fill="#FFFFFF"
      />
      <path
        d="M121.8,43.3c0,5,4.1,9.1,9.1,9.1c5,0,9.1-4.1,9.1-9.1c0-5-4.1-9.1-9.1-9.1C125.9,34.2,121.8,38.3,121.8,43.3z"
        fill="#FFFFFF"
      />
      <path
        d="M43.8,14.1L43.8,14.1c0-0.1,0-0.1,0.1-0.2c-4.6-1.1-8.2-1.6-11.3-1.6c-4.4,0-7.6,1.1-9.6,3.3
      c-3.2,3.5-2.1,9.2-1.5,11.5c1.1,4.1,3,6.9,4.4,8.3C29.6,33.1,38.3,26.8,43.8,14.1z"
        fill="#FFFFFF"
      />
      <path
        d="M515.3,62.7l-11.6-2.2c-3.5-0.7-5.2-2.4-5.2-5.1c0-1.7,0.7-3.1,2.2-4.3c1.5-1.1,3.9-1.7,7.2-1.7
      c1.8,0,3.7,0.3,5.7,0.8c2,0.5,4.2,1.3,6.7,2.5c1.8,0.9,3.4,0.9,4.8,0.2c1.3-0.7,2.3-1.8,2.9-3.3c0.6-1.5,0.7-3,0.3-4.6
      c-0.4-1.6-1.5-2.8-3.2-3.6c-5-2.6-10.8-4-17.3-4c-4.7,0-8.9,0.8-12.5,2.3c-3.6,1.6-6.5,3.7-8.5,6.5c-2.1,2.8-3.1,6.1-3.1,10
      c0,4.3,1.2,7.7,3.7,10.4c2.4,2.6,6,4.4,10.6,5.3l11.6,2.2c3.8,0.7,5.7,2.4,5.7,5.1c0,1.7-0.7,3.1-2.2,4.1c-1.5,1-3.8,1.6-6.9,1.6
      c-2.4,0-4.8-0.3-7.4-0.8c-2.6-0.5-5.3-1.5-8.4-2.8c-1.8-0.8-3.3-0.8-4.7-0.1c-1.3,0.7-2.3,1.8-2.8,3.3c-0.5,1.5-0.5,3,0.1,4.6
      c0.6,1.6,1.8,2.8,3.6,3.9c2.8,1.5,5.9,2.5,9.4,3.2c3.4,0.6,6.8,0.9,10,0.9c7.2,0,13.1-1.7,17.5-5c4.4-3.3,6.7-8,6.7-14
      c0-4.4-1.3-7.8-3.8-10.3C523.7,65.2,520.1,63.5,515.3,62.7z"
        fill="#FFFFFF"
      />
      <path
        d="M237.4,40.9c-3.6-2.4-7.7-3.6-12.5-3.6c-3.8,0-7.2,0.9-10.2,2.6c-3,1.7-5.3,4-6.9,6.9v-0.4
      c0-5.9-2.8-8.9-8.3-8.9c-5.5,0-8.2,3-8.2,8.9v62.5c0,6,2.8,9,8.4,9c5.5,0,8.3-3,8.3-9V88.1c1.6,2.7,3.9,4.9,6.9,6.5
      c3,1.6,6.3,2.5,9.9,2.5c4.7,0,8.9-1.2,12.5-3.7c3.6-2.5,6.4-5.9,8.4-10.4c2-4.5,3-9.8,3-15.9c0-6.1-1-11.4-3-15.9
      C243.7,46.8,240.9,43.3,237.4,40.9z M228.5,79.8c-2.2,2.7-5.1,4.1-8.7,4.1c-3.6,0-6.5-1.4-8.8-4.1c-2.2-2.7-3.3-7-3.3-12.7
      c0-5.7,1.1-9.9,3.3-12.5c2.2-2.7,5.1-4,8.8-4c3.5,0,6.4,1.3,8.7,4c2.2,2.7,3.3,6.9,3.3,12.5C231.9,72.8,230.8,77.1,228.5,79.8z"
        fill="#FFFFFF"
      />
      <path
        d="M268,14.6c-5.6,0-8.4,3.2-8.4,9.7v23.3v16.6v23.3c0,3,0.7,5.4,2.2,7.1c1.4,1.7,3.5,2.5,6.3,2.5
      c2.7,0,4.8-0.8,6.2-2.5c1.4-1.7,2.1-4,2.1-7.1V64.2V47.6V24.3c0-3.1-0.7-5.5-2.2-7.2C272.7,15.5,270.7,14.6,268,14.6z"
        fill="#FFFFFF"
      />
      <path
        d="M313.7,37.3c-2.6,0-5.4,0.3-8.5,0.9c-3.1,0.6-6.3,1.6-9.8,3c-2.1,0.8-3.5,2-4.2,3.7c-0.7,1.7-0.8,3.4-0.4,5.1
      c0.4,1.7,1.4,2.9,2.8,3.7c1.4,0.8,3.1,0.8,5.2-0.2c2.7-1.2,5.3-2,7.7-2.5c2.4-0.5,4.5-0.8,6.5-0.8c3.5,0,6.1,0.7,7.5,2.2
      c1.5,1.4,2.2,3.9,2.2,7.3v2h-4c-7.7,0-13.9,0.5-18.5,1.6c-4.7,1.1-8,2.8-10.1,5.3c-2.1,2.5-3.1,5.8-3.1,9.9c0,3.5,0.9,6.7,2.7,9.5
      c1.8,2.8,4.3,5,7.4,6.7c3.1,1.6,6.7,2.5,10.8,2.5c3.8,0,7-0.9,9.6-2.7c2.6-1.8,4.5-4.2,5.6-7.2v0.7c0,6,2.6,9,7.9,9
      c5.2,0,7.8-3,7.8-9V61.9c0-8.4-2.1-14.6-6.2-18.6C328.4,39.3,322.1,37.3,313.7,37.3z M322.8,73.2c0,3.6-1.1,6.5-3.2,8.8
      c-2.1,2.3-4.8,3.4-7.9,3.4c-2.7,0-4.8-0.7-6.3-2.1c-1.4-1.4-2.2-3.2-2.2-5.4c0-2.6,1.2-4.4,3.5-5.4c2.4-1.1,6.4-1.6,12.1-1.6h3.9
      V73.2z"
        fill="#FFFFFF"
      />
      <path
        d="M399,40.2c-2.7,0-4.7,0.8-6.2,2.3c-1.4,1.5-2.2,3.7-2.2,6.6v0.2c-1.5-2.8-3.8-5.1-6.9-6.8
      c-3.1-1.7-6.5-2.6-10.3-2.6c-4.9,0-9.1,1.2-12.8,3.6c-3.6,2.4-6.4,5.7-8.4,10c-2,4.3-3,9.3-3,14.9c0,5.8,1,10.8,3,15.1
      c2,4.3,4.8,7.6,8.4,10c3.6,2.4,7.9,3.6,12.8,3.6c3.8,0,7.2-0.9,10.4-2.7c3.2-1.8,5.5-4.2,7-7.1v6.5c0,9.2-4.7,13.8-14.2,13.8
      c-2,0-4.4-0.2-7.2-0.7c-2.8-0.5-5.8-1.5-9-3c-1.7-0.8-3.2-0.8-4.6,0.1c-1.4,0.8-2.4,2-3,3.7c-0.6,1.6-0.6,3.3-0.1,5
      c0.6,1.7,2,3,4.1,4c2.9,1.4,6.1,2.5,9.8,3.2c3.6,0.7,7.4,1.1,11.4,1.1c9.5,0,16.7-2.4,21.6-7.2c5-4.8,7.4-12.1,7.4-21.7V49.1
      C407.2,43.1,404.4,40.2,399,40.2z M387.4,79.8c-2.3,2.7-5.2,4.1-8.9,4.1c-3.8,0-6.8-1.4-9-4.1c-2.2-2.7-3.3-6.5-3.3-11.3
      c0-4.8,1.1-8.6,3.3-11.3c2.2-2.7,5.2-4,9-4c3.7,0,6.7,1.3,8.9,4c2.3,2.7,3.4,6.4,3.4,11.3C390.7,73.3,389.6,77,387.4,79.8z"
        fill="#FFFFFF"
      />
      <path
        d="M471.7,66.5c0-8.9-2.3-16.1-7-21.3c-4.7-5.3-10.9-7.9-18.9-7.9c-5.5,0-10.3,1.3-14.5,3.8
      c-4.2,2.5-7.5,6-9.9,10.5c-2.4,4.5-3.6,9.7-3.6,15.6c0,6.1,1.2,11.3,3.7,15.8c2.5,4.5,6,7.9,10.6,10.4c4.6,2.5,10.1,3.7,16.6,3.7
      c2.9,0,5.9-0.4,9-1.1c3.1-0.7,6-1.8,8.6-3.3c1.9-1.1,3.2-2.5,3.7-4.1c0.6-1.7,0.6-3.3,0-4.8c-0.6-1.6-1.6-2.7-3.1-3.3
      c-1.5-0.7-3.3-0.4-5.4,0.6c-2.1,1.1-4.2,1.8-6.5,2.3c-2.3,0.4-4.3,0.6-6,0.6c-4.5,0-8-1-10.6-3.1c-2.6-2.1-4.1-5.4-4.7-9.9h32.6
      C469.9,70.9,471.7,69.4,471.7,66.5z M433.8,61.8c0.5-4,1.9-7.2,4-9.5c2.2-2.3,5-3.4,8.6-3.4c7.1,0,10.9,4.3,11.3,12.8H433.8z"
        fill="#FFFFFF"
      />
      <path
        d="M550.5,76.5c-3,0-5.3,0.9-7,2.7c-1.7,1.8-2.6,4.2-2.6,7.1c0,3,0.9,5.4,2.6,7.2c1.7,1.9,4.1,2.8,7,2.8
      c3,0,5.3-0.9,6.9-2.8c1.7-1.9,2.5-4.3,2.5-7.2c0-2.9-0.8-5.2-2.5-7.1C555.7,77.5,553.4,76.5,550.5,76.5z"
        fill="#FFFFFF"
      />
      <path
        d="M656.3,46.5c-0.1-1.7-1-3.1-2.6-4.3c-1.6-1.2-3.5-1.7-5.7-1.5c-1.9,0.1-3.7,0.5-5.5,1
      c-1.8,0.6-3.5,1.4-5.1,2.4c-1,0.6-1.9,1.4-2.7,2.2c-0.2-2.5-0.9-4.5-2.1-5.9c-1.4-1.7-3.5-2.5-6.2-2.5c-5.6,0-8.4,3.2-8.4,9.7v39.9
      c0,3,0.7,5.4,2.2,7.1c1.4,1.7,3.5,2.5,6.3,2.5c2.7,0,4.8-0.8,6.2-2.5c1.4-1.7,2.1-4,2.1-7.1V57.8c0.2-0.2,0.3-0.5,0.5-0.7
      c1.1-1.4,2.3-2.5,3.6-3.2c1.3-0.7,2.7-1.1,4-1.2c1.5-0.1,2.8,0.1,4,0.5c1.2,0.4,2.5,0.6,3.9,0.5c1.5-0.1,2.8-0.7,4-1.9
      C656,50.7,656.5,48.9,656.3,46.5z"
        fill="#FFFFFF"
      />
      <path
        d="M610.6,15.7c-1.9-0.6-3.8-0.8-5.8-0.8c-9,0-15.8,2.3-20.3,6.8c-4.5,4.5-6.8,10-6.8,16.5v5.5h-2.6
      c-3.1,0-5.5,0.7-7.2,2.2c-1.7,1.5-2.5,3.6-2.5,6.3c0,5.7,3.2,8.6,9.7,8.6h2.6v26.3c0,3.1,0.7,5.5,2.2,7.2c1.4,1.7,3.5,2.6,6.3,2.6
      c2.4,0,4.3-0.7,5.7-2c0.2-0.2,0.4-0.4,0.6-0.6c1.4-1.7,2.1-4.1,2.1-7.2V60.8h3.6c3,0,5.4-0.7,7.1-2.2c1.7-1.5,2.5-3.6,2.5-6.4
      c0-2.8-0.8-4.9-2.5-6.3c-1.7-1.4-4-2.1-7.1-2.1h-3.8c-0.1-0.6-0.2-1.1-0.4-1.6v-4c0-1,0.2-2.2,0.7-3.5c0.5-1.3,1.3-2.5,2.5-3.5
      c1.2-1,3-1.5,5.2-1.5c1.5,0,3.1,0.2,4.7,0.6c1.6,0.4,2.8,0.6,3.7,0.6c1.7,0,3.2-0.7,4.5-2c1.3-1.3,2-3.1,2-5.4c0-2-0.6-3.7-1.9-4.9
      C614.1,17.2,612.5,16.3,610.6,15.7z"
        fill="#FFFFFF"
      />
    </g>
  </svg>
)

import { PayloadAction, createSelector } from '@reduxjs/toolkit'
import { BookingPeriod } from '@spiaggeit/spit-datepicker'
import { DateTime } from 'luxon'

import {
  HALF_DAY_QUERY_PARAM,
  getBookingPeriodFromHalfDay,
} from '../utils/halfDay'

import { createAppSlice } from './createAppSlice'

type Period = { start: string; end: string }

export type PeriodSliceState = {
  period: Period | null
  bookingPeriod: BookingPeriod | null
}

const initialState: PeriodSliceState = {
  bookingPeriod: null,
  period: null,
}

export const insertPeriodSlice = createAppSlice({
  initialState,
  name: 'insertPeriod',
  reducers: (create) => ({
    setBookingPeriod: create.reducer(
      (state, action: PayloadAction<BookingPeriod | null>) => {
        state.bookingPeriod = action.payload
      }
    ),
    setPeriod: create.reducer((state, action: PayloadAction<Period | null>) => {
      state.period = action.payload
    }),
    setupByUrl: create.reducer((state, action: PayloadAction<string>) => {
      const url = new URL(action.payload)
      const startDate = url.searchParams.get('startdate')
      const endDate = url.searchParams.get('enddate')
      const from = Number(url.searchParams.get('from'))
      const to = Number(url.searchParams.get('to'))
      const halfDay = getBookingPeriodFromHalfDay(
        Number(url.searchParams.get(HALF_DAY_QUERY_PARAM))
      )

      if (state.bookingPeriod === null) {
        state.bookingPeriod = halfDay
      }

      if (state.period) return

      if (startDate && endDate) {
        state.period = {
          end: endDate,
          start: startDate,
        }
      } else if (from && to) {
        state.period = {
          end: DateTime.fromSeconds(to).toFormat('yyyy-LL-dd'),
          start: DateTime.fromSeconds(from).toFormat('yyyy-LL-dd'),
        }
      }
    }),
  }),
  selectors: {
    bookingPeriod: (state) => state.bookingPeriod,
    period: (state) => state.period,
  },
})

export const periodAsUnixTimeSelector = createSelector(
  insertPeriodSlice.selectors.period,
  (period) => {
    if (!period) return null

    return {
      end: DateTime.fromFormat(period.end, 'yyyy-LL-dd', {
        zone: 'utc',
      }).toSeconds(),
      start: DateTime.fromFormat(period.start, 'yyyy-LL-dd', {
        zone: 'utc',
      }).toSeconds(),
    }
  }
)

import * as rd from '@devexperts/remote-data-ts'
import * as Sentry from '@sentry/react'
import { Button } from '@spiaggeit/spit-ui'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useInterval } from 'usehooks-ts'

import { ArrowLeft } from '../../assets/icons/ArrowLeft'
import { KioskTimeoutDialog } from '../../components/Dialog/KioskTimeoutDialog'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { useBackLink } from '../../hooks/useBackLink'
import { httpClient } from '../../lib/http/HttpClient'
import { CartResponse, ServicesResponse } from '../../models/cart'
import { appSlice } from '../../store/appSlice'
import { kioskSlice } from '../../store/kioskSlice'
import { licenseSlice } from '../../store/licenseSlice'
import { getKioskLocalSettings } from '../../utils/kiosk'
import { getLegacyUrl } from '../../utils/legacyUrl'
import { printReceipt } from '../../utils/printer'

import { PaymentKioskDialog } from './Dialog'

export type PaymentError = 'payment' | 'print'

const captureError = (error: unknown) => {
  Sentry.captureException(new Error(String(error)), {
    tags: {
      reduxSlice: 'kiosk',
    },
  })
}

export const PaymentKioskRoute = () => {
  const license = useAppSelector(licenseSlice.selectors.license)
  const country = useAppSelector(appSlice.selectors.country)
  const { t } = useTranslation()
  const backLink = useBackLink()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [error, setError] = useState<PaymentError | null>(null)
  const regId = searchParams.get('regId')
  const token = searchParams.get('token')
  const [cart, setCart] = useState<
    CartResponse['result']['cart']['reservationModels']
  >([])
  const [services, setServices] = useState<
    ServicesResponse['result']['beachServices']
  >({})
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const isPaymentComplete = useAppSelector(
    kioskSlice.selectors.isPaymentComplete
  )
  const paymentIntentId = useAppSelector(kioskSlice.selectors.paymentIntentId)
  const dispatch = useAppDispatch()
  const kioskSettings = useAppSelector(kioskSlice.selectors.settings)

  function showError(payload: PaymentError | null) {
    setError(payload)
    setIsDialogOpen(true)
  }

  function hideError() {
    setIsDialogOpen(false)
    setError(null)
  }

  async function setupPayment(payload: {
    licenseCode: string
    regId: string
    posTerminalId: string
  }) {
    try {
      const [cartResponse, servicesResponse] = await Promise.all([
        httpClient.fetch<CartResponse>(
          `beaches/${payload.licenseCode}/carts/${regId}`,
          country
        ),
        httpClient.fetch<ServicesResponse>(
          `beaches/${payload.licenseCode}/services`,
          country
        ),
        dispatch(
          kioskSlice.actions.createPaymentIntent({
            cartId: payload.regId,
            terminal: payload.posTerminalId,
          })
        ),
      ])
      if (
        cartResponse.status === 'success' &&
        servicesResponse.status === 'success'
      ) {
        setCart(cartResponse.data.result.cart.reservationModels)
        setServices(servicesResponse.data.result.beachServices)
      } else {
        throw new Error('Failed to fetch cart or services')
      }
    } catch (error) {
      captureError(error)
      showError('payment')
    }
  }

  useEffect(() => {
    // Fetch kiosk settings
    const kioskLocalSettings = getKioskLocalSettings()

    if (!kioskLocalSettings) return

    dispatch(
      kioskSlice.actions.fetchSettings({
        kioskId: kioskLocalSettings.kioskId,
      })
    )
  }, [])

  useEffect(() => {
    // Setup payment page
    if (!rd.isSuccess(kioskSettings) || !license || !regId) return

    setupPayment({
      licenseCode: license.license,
      posTerminalId: kioskSettings.value.posTerminalId,
      regId,
    })
  }, [kioskSettings, license, regId])

  useEffect(() => {
    // Show error if payment intent or kiosk settings are failed
    if (rd.isFailure(paymentIntentId) || rd.isFailure(kioskSettings)) {
      showError('payment')
    }
  }, [paymentIntentId, kioskSettings])

  useEffect(() => {
    // Print receipt
    const kioskLocalSettings = getKioskLocalSettings()
    if (
      !isPaymentComplete ||
      !rd.isSuccess(kioskSettings) ||
      !kioskLocalSettings ||
      !regId
    )
      return

    setError(null)
    setIsDialogOpen(true)

    dispatch(
      kioskSlice.actions.logPrintStart({
        kioskId: kioskLocalSettings.kioskId,
        reservationId: Number(regId),
      })
    )

    printReceipt(
      {
        ...kioskSettings.value.printer,
        width: kioskSettings.value.printer.invoiceWidth,
      },
      cart,
      Object.values(services),
      onPrintSuccess({
        kioskId: kioskLocalSettings.kioskId,
        reservationId: Number(regId),
      }),
      onPrintFailure({
        kioskId: kioskLocalSettings.kioskId,
        reservationId: Number(regId),
      })
    )
  }, [isPaymentComplete])

  useInterval(() => {
    if (!rd.isSuccess(paymentIntentId) || !license || isPaymentComplete) return

    dispatch(kioskSlice.actions.checkPaymentIntentStatus())
  }, 1000)

  function goToSummary() {
    if (!regId || !license || !token) return
    window.location.href = `${getLegacyUrl()}/${license.license}/registrationPaymentConfirm/?orderId=${regId}&p_con=1&pm=stripe&token=${token}`
  }

  const onPrintSuccess =
    (data: { kioskId: string; reservationId: number }) =>
      (
        receipt: {
        total: number
        date: string
        printerId: string
        paymentMethod: string
        receiptNumber: string
      } | null,
        shouldLog: boolean
      ) => {
        if (!regId || !token || !license) return

        if (shouldLog && receipt) {
          dispatch(
            kioskSlice.actions.logPrintSuccess({
              ...data,
              ...receipt,
              receiptDate: receipt.date,
            })
          )
        }
        hideError()
        goToSummary()
      }

  const onPrintFailure =
    (data: { kioskId: string; reservationId: number }) =>
      (
        printError: { status: number; responseText: string },
        shouldLog: boolean
      ) => {
        if (shouldLog) {
          dispatch(kioskSlice.actions.logPrintFailure(data))
        }
        // eslint-disable-next-line no-console
        console.error(printError)
        captureError(printError)
        showError('print')
      }

  function onPaymentRetry() {
    if (!regId || !license || !rd.isSuccess(kioskSettings)) return
    hideError()

    setupPayment({
      licenseCode: license.license,
      posTerminalId: kioskSettings.value.posTerminalId,
      regId,
    })
  }

  if (!license || !regId) return null

  return (
    <>
      <div className="flex flex-1 flex-col items-center justify-center p-4">
        <img
          className="w-full max-w-2xl"
          src={`https://img.spiagge.it/logo/${license.license}.png`}
        />

        <p className="mt-8 text-center text-sm">{t('kiosk.payment.message')}</p>
      </div>

      {backLink
        ? (
            <Button
              className="fixed bottom-6 left-6 border-gray-200 font-normal"
              color="error"
              fullWidth={false}
              onClick={() => {
                dispatch(kioskSlice.actions.abortPaymentIntent()).then(() => {
                  if (backLink) {
                    navigate(backLink)
                  }
                })
              }}
              type="button"
              variant="outline"
            >
              {t('chooseProduct.kiosk.cancel')}
            </Button>
          )
        : null}

      <ArrowLeft className="fixed bottom-0 left-1/2 h-12 w-12 -translate-x-1/2 -rotate-90 text-gray-400" />

      <PaymentKioskDialog
        error={error}
        isOpen={isDialogOpen}
        onPaymentCancel={() => {
          dispatch(kioskSlice.actions.abortPaymentIntent()).then(() => {
            if (backLink) {
              navigate(backLink)
            }
          })
        }}
        onPaymentRetry={onPaymentRetry}
        onPrintCancel={goToSummary}
        onPrintRetry={() => {
          const kioskLocalSettings = getKioskLocalSettings()
          if (
            !rd.isSuccess(kioskSettings) ||
            !rd.isSuccess(paymentIntentId) ||
            !kioskLocalSettings ||
            !regId
          )
            return

          printReceipt(
            {
              ...kioskSettings.value.printer,
              width: kioskSettings.value.printer.invoiceWidth,
            },
            cart,
            Object.values(services),
            onPrintSuccess({
              kioskId: kioskLocalSettings.kioskId,
              reservationId: Number(regId),
            }),
            onPrintFailure({
              kioskId: kioskLocalSettings.kioskId,
              reservationId: Number(regId),
            })
          )
        }}
        regId={regId}
      />

      <KioskTimeoutDialog
        onKill={() => dispatch(kioskSlice.actions.abortPaymentIntent())}
        timeout={300000}
      />
    </>
  )
}

import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { ChooseProductRoute } from '../../routes/ChooseProduct'
import { InsertPeriod } from '../../routes/InsertPeriod'
import { LandingKioskRoute } from '../../routes/LandingKiosk'
import { PaymentKioskRoute } from '../../routes/PaymentKiosk'
import { Root } from '../../routes/Root'
import { SettingsRoute } from '../../routes/Settings'
import { TicketsRoute } from '../../routes/Tickets'

import {
  CHOOSE_PRODUCT_PATH,
  INSERT_PERIOD_PATH,
  LANDING_KIOSK_PATH,
  PAYMENT_KIOSK_PATH,
  ROOT_PATH,
  SETTINGS_PATH,
  TICKETS_PATH,
} from './paths'

const router = createBrowserRouter([
  {
    children: [
      {
        element: <LandingKioskRoute />,
        path: LANDING_KIOSK_PATH,
      },
      {
        element: <InsertPeriod />,
        path: INSERT_PERIOD_PATH,
      },
      {
        element: <ChooseProductRoute />,
        path: CHOOSE_PRODUCT_PATH,
      },
      {
        element: <TicketsRoute />,
        path: TICKETS_PATH,
      },
      {
        element: <PaymentKioskRoute />,
        path: PAYMENT_KIOSK_PATH,
      },
    ],
    element: <Root />,
    path: ROOT_PATH,
  },
  {
    element: <SettingsRoute />,
    path: SETTINGS_PATH,
  },
])

export const Router = () => <RouterProvider router={router} />

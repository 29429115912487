import { httpClient } from '../lib/http/HttpClient'
import { assertNever } from '../lib/types/utils'
import { Country } from '../models/app'
import {
  ShouldValidateFormResponse,
  UnavailableSectorsResponse,
} from '../models/http'
import { SectorRule } from '../models/insertPeriod'
import { License } from '../models/license'
import { PeriodSliceState } from '../store/insertPeriodSlice'

type RuleData = Record<string, string>

export async function getShouldValidateForm({
  country,
  license,
  period,
  sectorRules,
}: {
  country: Country
  license: License | null
  period: PeriodSliceState['period']
  sectorRules: SectorRule[] | null
}) {
  if (!period || !license || !sectorRules) {
    return null
  }

  const ruleData = sectorRules.reduce<RuleData>((acc, rule) => {
    if (rule.requiresParameter) {
      if (rule.validationField === 'endDate') {
        acc['endDate'] = String(new Date(period.end).getTime() / 1000)
      }
    }

    return acc
  }, {})

  try {
    const resp = await httpClient.fetch<ShouldValidateFormResponse>(
      `beaches/${license.license}/sector-rules/should-validate-form`,
      country,
      {
        body: JSON.stringify(ruleData),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      }
    )

    switch (resp.status) {
      case 'error':
        return null
      case 'success':
        return resp.data.result.shouldValidate
      default:
        assertNever(resp)
    }
  } catch {
    // TODO handle error
  }

  return null
}

export async function getUnavailableSectors({
  country,
  license,
  period,
  sectorRules,
  taxCode,
}: {
  country: Country
  license: License | null
  period: PeriodSliceState['period']
  sectorRules: SectorRule[]
  taxCode: string
}) {
  if (!license || !period) {
    return null
  }

  try {
    const ruleData = sectorRules.reduce<RuleData>((acc, rule) => {
      if (rule.requiresParameter) {
        if (rule.validationField === 'endDate') {
          acc['endDate'] = String(new Date(period.end).getTime() / 1000)
        }
      } else {
        if (rule.validationField === 'taxCode') {
          acc['taxCode'] = taxCode
        }
      }

      return acc
    }, {})

    const resp = await httpClient.fetch<UnavailableSectorsResponse>(
      `beaches/${license.license}/sector-rules/get-unavailable-sectors`,
      country,
      {
        body: JSON.stringify(ruleData),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      }
    )

    switch (resp.status) {
      case 'error':
        return null
      case 'success':
        return resp.data.result.unavailableSectors
      default:
        assertNever(resp)
    }
  } catch {
    // TODO handle error
  }

  return null
}

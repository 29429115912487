import { combineSlices, configureStore } from '@reduxjs/toolkit'

import { appSlice } from './appSlice'
import { bookingAvailabilitySlice } from './bookingAvailabilitySlice'
import { cartSlice } from './cartSlice'
import { insertPeriodSlice } from './insertPeriodSlice'
import { kioskSlice } from './kioskSlice'
import { licenseSlice } from './licenseSlice'
import { sectorRulesSlice } from './sectorRulesSlice'
import { servicesSlice } from './servicesSlice'
import { toastSlice } from './toastSlice'

export const rootReducer = combineSlices(
  appSlice,
  bookingAvailabilitySlice,
  cartSlice,
  licenseSlice,
  insertPeriodSlice,
  kioskSlice,
  servicesSlice,
  sectorRulesSlice,
  toastSlice
)

export const store = configureStore({
  devTools: import.meta.env.MODE === 'development',
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export type AppStore = typeof store

export type AppDispatch = AppStore['dispatch']

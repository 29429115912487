/* eslint-disable import/no-default-export */
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

export const I18N_QS_KEY = 'lang'
export const I18N_COOKIE_KEY = 'spiagge-locale'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      lookupCookie: I18N_COOKIE_KEY,
      lookupQuerystring: I18N_QS_KEY,
      order: ['querystring', 'cookie', 'navigator'],
    },
    fallbackLng: 'en',
    supportedLngs: ['de', 'es', 'en', 'fr', 'it', 'pt'],
  })

export default i18n

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '../../hooks/store'
import { cartSlice } from '../../store/cartSlice'
import { servicesSlice } from '../../store/servicesSlice'

import { Ticket } from './Ticket'

interface Props {
  availableQuantity: number
  hasLimit: boolean
  name: string
  tickets: {
    availableQuantity: number
    hasLimit: boolean
    icon: string
    minimumQuantity: number
    name: string
    price: number
    serviceId: number
  }[]
  onAdd: VoidFunction
  onRemove: VoidFunction
}

export const Group = (props: Props) => {
  const minimumQuantity = props.tickets.reduce(
    (acc, ticket) => acc + ticket.minimumQuantity,
    0
  )
  const [quantity, setQuantity] = useState(minimumQuantity)
  const services = useAppSelector(servicesSlice.selectors.self)
  const totalQuantity = useAppSelector(cartSlice.selectors.totalQuantity)
  const { t } = useTranslation()

  if (services.status !== 'success' || props.tickets.length === 0) return

  return (
    <div className="py-8">
      <h3 className="font-bold md:text-lg">{props.name}</h3>

      {props.hasLimit && props.availableQuantity > 0
        ? (
            <p className="mt-1">
              {t('tickets.group.max', { value: props.availableQuantity })}
            </p>
          )
        : null}

      <div className="mt-3 space-y-6 md:rounded md:border md:border-gray-50 md:p-4 md:shadow">
        {props.tickets.map((ticket, index) => (
          <Ticket
            available={{
              global: services.data.hasLimit
                ? Math.max(0, services.data.availableQuantity - totalQuantity)
                : Infinity,
              group: props.hasLimit
                ? Math.max(0, props.availableQuantity - quantity)
                : Infinity,
              ticket: ticket.availableQuantity,
            }}
            hasLimit={ticket.hasLimit}
            icon={ticket.icon}
            id={ticket.serviceId}
            key={index}
            minimumQuantity={ticket.minimumQuantity}
            name={ticket.name}
            onAdd={() => setQuantity((a) => a + 1)}
            onRemove={() => setQuantity((a) => a - 1)}
            price={ticket.price}
          />
        ))}
      </div>
    </div>
  )
}

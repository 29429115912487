import { DateTime } from 'luxon'

import { httpClient } from '../lib/http/HttpClient'
import { assertNever } from '../lib/types/utils'
import { License } from '../models/license'

import { appSlice } from './appSlice'
import { RootState } from './configureStore'
import { createAppSlice } from './createAppSlice'

export type LicenseSliceState =
  | {
      license: License
      isBookingEnabled: boolean
      seasonStartYear: number
      seasonEndYear: number
      // TODO use milliseconds instead of string
      seasonStart: string
      seasonEnd: string
      seasonalStart: string
      seasonalEnd: string
      status: 'success'
    }
  | {
      license: null
      status: 'idle' | 'loading' | 'error'
    }

const initialState: LicenseSliceState = {
  license: null,
  status: 'idle',
}

export const licenseSlice = createAppSlice({
  initialState: initialState satisfies LicenseSliceState as LicenseSliceState,
  name: 'license',
  reducers: (create) => ({
    loadLicense: create.asyncThunk<License, string, { rejectValue: null }>(
      async (licenseName, thunkApi): Promise<License> => {
        const rootState = thunkApi.getState() as RootState
        const country = appSlice.selectors.country(rootState)

        const resp = await httpClient.fetch<{ result: { license: License } }>(
          `beaches/${licenseName}?expand=beach`,
          country
        )

        switch (resp.status) {
          case 'success':
            return resp.data.result.license

          case 'error':
            throw thunkApi.rejectWithValue(null)

          default:
            assertNever(resp)
        }
      },
      {
        fulfilled: (_, action) => {
          const license = action.payload

          const now = DateTime.utc()
          let seasonStartYear = now.year
          let seasonEndYear = seasonStartYear
          const seasonEnd = DateTime.fromFormat(
            `${license.bookingEndPeriod}-${seasonStartYear}`,
            'dd-LL-yyyy'
          ).toMillis()

          // New year enabled, current season not over yet and is october or later -> next year calendar
          if (license.bookingNextYear && now.month > 8) {
            seasonEndYear++
            if (now.toMillis() > seasonEnd) {
              seasonStartYear++
            }
          }

          const isBookingEnabled = !!license.bookingStatus
          const seasonalStartDate = DateTime.fromFormat(
            `${license.seasonalStartDate}-${seasonEndYear}`,
            'dd-LL-yyyy'
          )

          return {
            isBookingEnabled,
            license,
            seasonEnd: `${license.bookingEndPeriod}-${seasonEndYear}`,
            seasonEndYear,
            seasonStart: `${license.bookingStartPeriod}-${seasonStartYear}`,
            seasonStartYear,
            seasonalEnd: `${license.seasonalEndDate}-${seasonEndYear}`,
            seasonalStart: (now.toMillis() >= seasonalStartDate.toMillis()
              ? now.plus({ days: license.bookingToday ? 0 : 1 })
              : seasonalStartDate
            ).toFormat('dd-LL-yyyy'),
            status: 'success',
          }
        },
        options: {
          condition: (_, thunkApi) => {
            const state = thunkApi.getState() as RootState
            if (state.license.status !== 'idle') {
              return false
            }
          },
        },
        pending: () => {
          return {
            license: null,
            status: 'loading',
          }
        },
        rejected: () => {
          return { license: null, status: 'error' }
        },
      }
    ),
  }),
  selectors: {
    beachLocation: (state) => {
      if (state.status !== 'success') return null
      const city = state.license.beach.city
      const stateName =
        state.license.beach.stateModel?.name ?? state.license.beach.state
      return stateName.length === 0 || city === stateName
        ? city
        : `${city}, ${stateName}`
    },
    isBookingEnabled: (state) =>
      state.status === 'success' ? state.isBookingEnabled : false,
    license: (state) => state.license,
    seasonEnd: (state) => (state.status === 'success' ? state.seasonEnd : null),
    seasonStart: (state) =>
      state.status === 'success' ? state.seasonStart : null,
    seasonStartYear: (state) =>
      state.status === 'success' ? state.seasonStartYear : null,
    seasonalEnd: (state) =>
      state.status === 'success' ? state.seasonalEnd : null,
    seasonalStart: (state) =>
      state.status === 'success' ? state.seasonalStart : null,
    status: (state) => state.status,
  },
})

import { cn } from '@spiaggeit/spit-ui'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { I18N_COOKIE_KEY, I18N_QS_KEY } from '../../app/i18n'
import { Flags } from '../../assets/icons/flags'

import { Current } from './Current'
import { Item } from './Item'

function setLanguageCookie(language: string) {
  const splittedHostname = window.location.hostname.split('.')
  const cookieDomain =
    splittedHostname.length > 2
      ? `.${splittedHostname.slice(1).join('.')}`
      : window.location.hostname

  Cookies.set(I18N_COOKIE_KEY, language, {
    domain: cookieDomain,
  })
  // Old cart reads the language and sets it in a new cookie, so we need to set it here as well beacuse we don't hit the endpoint that sets the cookie
  Cookies.set('yb_booking_lang', language, {
    domain: cookieDomain,
  })
}

export const LanguageSwitcher = () => {
  const { i18n, t } = useTranslation()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get(I18N_QS_KEY)) {
      setLanguageCookie(searchParams.get(I18N_QS_KEY) as string)
    } else {
      setLanguageCookie(i18n.language)
    }
  }, [])

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language)
    setLanguageCookie(language)
    setIsDropdownOpen(false)
  }

  return (
    <div className="relative">
      <button onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        <Current />
      </button>

      <ul
        className={cn(
          'absolute right-0 top-full w-36 rounded-sm bg-white p-2 shadow-md',
          {
            hidden: !isDropdownOpen,
          }
        )}
      >
        <Item
          isActive={i18n.language === 'it'}
          onClick={() => changeLanguage('it')}
        >
          <>
            <Flags.IT />

            <div>{t('languageSwitcher.it')}</div>
          </>
        </Item>

        <Item
          isActive={i18n.language === 'en'}
          onClick={() => changeLanguage('en')}
        >
          <>
            <Flags.EN />

            <div>{t('languageSwitcher.en')}</div>
          </>
        </Item>

        <Item
          isActive={i18n.language === 'fr'}
          onClick={() => changeLanguage('fr')}
        >
          <>
            <Flags.FR />

            <div>{t('languageSwitcher.fr')}</div>
          </>
        </Item>

        <Item
          isActive={i18n.language === 'de'}
          onClick={() => changeLanguage('de')}
        >
          <>
            <Flags.DE />

            <div>{t('languageSwitcher.de')}</div>
          </>
        </Item>

        <Item
          isActive={i18n.language === 'es'}
          onClick={() => changeLanguage('es')}
        >
          <>
            <Flags.ES />

            <div>{t('languageSwitcher.es')}</div>
          </>
        </Item>

        <Item
          isActive={i18n.language === 'pt'}
          onClick={() => changeLanguage('pt')}
        >
          <>
            <Flags.PT />

            <div>{t('languageSwitcher.pt')}</div>
          </>
        </Item>
      </ul>
    </div>
  )
}

import { cn } from '@spiaggeit/spit-ui'
import {
  matchPath,
  useLocation,
  //useSearchParams,
} from 'react-router-dom'

interface Props {
  href: string
  label: string
}

export const Step = (props: Props) => {
  const location = useLocation()
  const isActive = matchPath(props.href, location.pathname) !== null
  //const [searchParams] = useSearchParams();
  //const search = searchParams.toString();
  //const to = `${props.href}${search ? `?${search}` : ''}`;

  return (
    <div className="flex-0 flex flex-col items-center">
      {isActive
        ? (
            <div className="flex-0 rounded-circle border border-yellow-200 p-1">
              <div className="h-2 w-2 rounded-circle bg-yellow-200 md:h-3 md:w-3" />
            </div>
          )
        : (
            <div className="flex-0 border-secondary h-4 w-4 rounded-circle border md:h-5 md:w-5" />
          )}

      <div
        className={cn(
          'absolute translate-y-6 text-xs text-secondary md:translate-y-full md:text-sm',
          { 'font-bold': isActive }
        )}
      >
        {props.label}
      </div>
    </div>
  )
}

export const ChevronUp = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.1667 11.1753L10.5917 7.64201C10.5142 7.5639 10.4221 7.5019 10.3205 7.4596C10.219 7.41729 10.11 7.39551 10 7.39551C9.89003 7.39551 9.78111 7.41729 9.67956 7.4596C9.57801 7.5019 9.48584 7.5639 9.40837 7.64201L5.87504 11.1753C5.79693 11.2528 5.73494 11.345 5.69263 11.4465C5.65032 11.5481 5.62854 11.657 5.62854 11.767C5.62854 11.877 5.65032 11.9859 5.69263 12.0875C5.73494 12.189 5.79693 12.2812 5.87504 12.3587C6.03117 12.5139 6.24238 12.601 6.46254 12.601C6.68269 12.601 6.8939 12.5139 7.05004 12.3587L10 9.40867L12.95 12.3587C13.1053 12.5126 13.3148 12.5994 13.5334 12.6003C13.643 12.601 13.7518 12.58 13.8533 12.5385C13.9548 12.497 14.0472 12.4359 14.125 12.3587C14.2059 12.284 14.2712 12.194 14.3172 12.094C14.3631 11.9939 14.3887 11.8857 14.3926 11.7757C14.3965 11.6657 14.3785 11.556 14.3397 11.4529C14.301 11.3499 14.2421 11.2555 14.1667 11.1753Z"
      fill="#797B8A"
    />
  </svg>
)

export const KioskSpot = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg
    fill="none"
    height="80"
    width="80"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="#F6F8FA" height="80" rx="40" width="80" />

    <path
      clipRule="evenodd"
      d="M29.268 25.412a2 2 0 0 1 3.464-2l.056.096A18 18 0 0 1 55.588 31a2 2 0 0 1-.732 2.732l-12.124 7 8 13.857c.03.052.057.104.082.158a5.587 5.587 0 0 1 2.101-.542c2.024-.091 4.018.858 5.449 2.794.87 1.177 1.658 1.261 2.164 1.176.686-.116 1.215-.603 1.362-.901l3.23 1.59c-.677 1.374-2.207 2.559-3.992 2.86-1.964.333-4.059-.419-5.66-2.585-.786-1.065-1.676-1.37-2.39-1.337-.743.033-1.515.438-2.042 1.215-.826 1.217-2.482 2.44-4.327 2.713-2.023.3-4.292-.347-6.11-2.499-1.725-2.043-4.331-1.712-5.577-.18-.72 1.293-2.19 2.385-3.894 2.673-1.964.333-4.058-.419-5.66-2.585-.786-1.065-1.675-1.37-2.39-1.337-.756.034-1.541.452-2.069 1.255L18 57.081c1.12-1.704 2.93-2.786 4.915-2.876 2.024-.091 4.018.858 5.449 2.794.87 1.177 1.658 1.261 2.164 1.176.686-.116 1.215-.603 1.362-.901l.175-.285c2.552-3.4 7.977-3.997 11.285-.08 1.006 1.19 2.032 1.379 2.832 1.26.792-.117 1.373-.528 1.636-.823a6.14 6.14 0 0 1 .065-.098 1.991 1.991 0 0 1-.615-.66l-8-13.856-12.124 7A2 2 0 0 1 24.412 49a18 18 0 0 1 4.912-23.492l-.056-.096ZM33 27.876a14 14 0 0 0-6 17.32L51 31.34a14 14 0 0 0-18-3.464Z"
      fill="#FFC100"
      fillRule="evenodd"
    />
  </svg>
)

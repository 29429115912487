import * as rd from '@devexperts/remote-data-ts'
import { useEffect } from 'react'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'

import { INSERT_PERIOD_PATH, TICKETS_PATH } from '../../app/router/paths'
import { useAppSelector } from '../../hooks/store'
import { AppMode } from '../../models/app'
import { appSlice } from '../../store/appSlice'
import {
  BookingAvailability,
  BookingFlow,
  bookingAvailabilitySlice,
} from '../../store/bookingAvailabilitySlice'
import {
  insertPeriodSlice,
  periodAsUnixTimeSelector,
} from '../../store/insertPeriodSlice'
import { licenseSlice } from '../../store/licenseSlice'
import { BOOKING_FLOW_QUERY_PARAM } from '../../utils/bookingFlow'
import {
  HALF_DAY_QUERY_PARAM,
  getHalfDayValueFromBookingPeriod,
} from '../../utils/halfDay'
import { getLegacyUrl } from '../../utils/legacyUrl'

import { ChooseProductDefault } from './Default'
import { ChooseProductKiosk } from './Kiosk'

function getSearchString(payload: {
  searchParams: URLSearchParams
  period: { start: number; end: number }
  bookingFlow?: BookingFlow
  bookingPeriod: ReturnType<typeof insertPeriodSlice.selectors.bookingPeriod>
}): string {
  payload.searchParams.set('from', String(payload.period.start))
  payload.searchParams.set('to', String(payload.period.end))
  payload.searchParams.set(
    HALF_DAY_QUERY_PARAM,
    String(getHalfDayValueFromBookingPeriod(payload.bookingPeriod))
  )

  if (payload.bookingFlow !== undefined) {
    payload.searchParams.set(
      BOOKING_FLOW_QUERY_PARAM,
      payload.bookingFlow.toString()
    )
  }

  return payload.searchParams.toString()
}

export const ChooseProductRoute = () => {
  const navigate = useNavigate()
  const bookingAvailability = useAppSelector(
    bookingAvailabilitySlice.selectors.self
  )
  const areTicketsAvailable = useAppSelector(
    bookingAvailabilitySlice.selectors.areTicketsAvailable
  )
  const areSpotsAvailable = useAppSelector(
    bookingAvailabilitySlice.selectors.areSpotsAvailable
  )
  const license = useAppSelector(licenseSlice.selectors.license)
  const periodAsUnixTime = useAppSelector(periodAsUnixTimeSelector)
  const [searchParams] = useSearchParams()
  const mode = useAppSelector(appSlice.selectors.mode)
  const bookingPeriod = useAppSelector(
    insertPeriodSlice.selectors.bookingPeriod
  )

  useEffect(() => {
    if (!license) {
      return
    }

    if (
      rd.isFailure(bookingAvailability) ||
      (rd.isSuccess(bookingAvailability) &&
        bookingAvailability.value.type !==
          BookingAvailability.SPOTS_AND_TICKETS) ||
      !periodAsUnixTime ||
      !license
    ) {
      navigate(
        `${generatePath(INSERT_PERIOD_PATH, { license: license.license })}?${searchParams.toString()}`
      )
    }
  }, [bookingAvailability])

  if (!rd.isSuccess(bookingAvailability) || !license || !periodAsUnixTime) {
    return null
  }

  const bookSpotsLink = `${getLegacyUrl()}/${license.license}/insertMap/?${getSearchString(
    {
      bookingFlow: mode === AppMode.WEBSITE ? BookingFlow.SPOTS : undefined,
      bookingPeriod,
      period: periodAsUnixTime,
      searchParams,
    }
  )}`

  const bookTicketsLink = `${generatePath(TICKETS_PATH, { license: license.license })}?${getSearchString(
    {
      bookingFlow: mode === AppMode.WEBSITE ? BookingFlow.TICKETS : undefined,
      bookingPeriod,
      period: periodAsUnixTime,
      searchParams,
    }
  )}`

  if (mode === AppMode.KIOSK) {
    return (
      <ChooseProductKiosk
        areSpotsAvailable={areSpotsAvailable}
        areTicketsAvailable={areTicketsAvailable}
        bookSpotsLink={bookSpotsLink}
        bookTicketsLink={bookTicketsLink}
        disabledTicketsLink={bookTicketsLink}
      />
    )
  }

  return (
    <ChooseProductDefault
      areSpotsAvailable={areSpotsAvailable}
      areTicketsAvailable={areTicketsAvailable}
      bookSpotsLink={bookSpotsLink}
      bookTicketsLink={bookTicketsLink}
      disabledTicketsLink={bookTicketsLink}
    />
  )
}

export const Logo = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg
    fill="none"
    viewBox="0 0 137 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_341_561)">
      <path
        d="M43.7537 15.0296C43.7537 14.1557 43.5044 13.4546 43.0118 12.9489C42.5192 12.4431 41.7873 12.0956 40.8383 11.9208L38.4958 11.4644C37.766 11.3143 37.398 10.9381 37.398 10.348C37.3974 10.1666 37.4396 9.98767 37.5212 9.82656C37.6028 9.66545 37.7212 9.52692 37.8665 9.42277C38.1741 9.18222 38.6708 9.06091 39.3544 9.06091C39.7433 9.06516 40.1303 9.11833 40.5065 9.21922C40.9715 9.34787 41.4233 9.52207 41.8556 9.7394C41.9917 9.82059 42.1451 9.86642 42.3025 9.87287C42.4599 9.87933 42.6165 9.8462 42.7584 9.77641C43.012 9.63244 43.2078 9.40166 43.3113 9.12465C43.4246 8.83919 43.4437 8.52371 43.3656 8.22616C43.3212 8.07089 43.2442 7.92737 43.14 7.80558C43.0359 7.6838 42.907 7.58667 42.7624 7.52095C41.6934 6.96769 40.5081 6.69029 39.3102 6.71293C38.4555 6.69603 37.6064 6.85764 36.8149 7.18787C36.1447 7.46602 35.56 7.92335 35.122 8.51195C34.7103 9.10247 34.4985 9.8143 34.5188 10.5392C34.4859 11.3063 34.7438 12.0568 35.2386 12.6343C35.7171 13.1647 36.4269 13.5225 37.3458 13.6952L39.6741 14.1516C40.4603 14.2997 40.8604 14.6759 40.8604 15.2681C40.8621 15.447 40.8197 15.6235 40.7371 15.7812C40.6546 15.939 40.5344 16.0729 40.3879 16.1707C40.0822 16.3927 39.6037 16.5058 38.9804 16.5058C38.4773 16.5029 37.9759 16.4457 37.4845 16.3351C36.9019 16.2005 36.3341 16.0062 35.7895 15.7553C35.654 15.6816 35.5033 15.6419 35.3499 15.6394C35.1964 15.6369 35.0446 15.6717 34.9068 15.7409C34.656 15.8816 34.4669 16.1146 34.378 16.3927C34.2778 16.6827 34.2813 16.9996 34.3881 17.2871C34.5194 17.6198 34.7675 17.8904 35.0838 18.0457C35.6705 18.3564 36.3005 18.5727 36.9517 18.6872C37.6149 18.8125 38.2879 18.8765 38.9623 18.8784C40.3999 18.8784 41.5762 18.5351 42.4488 17.8504C43.3214 17.1658 43.7537 16.2344 43.7537 15.0296Z"
        fill={props.fill ?? 'white'}
      />
      <path
        d="M56.6258 16.0226C57.0469 14.9941 57.2524 13.8868 57.229 12.772C57.2535 11.6605 57.0479 10.5563 56.6258 9.53174C56.2716 8.68945 55.6926 7.96622 54.955 7.44487C54.2209 6.95144 53.3582 6.69631 52.4799 6.71293C51.7729 6.70332 51.0756 6.88262 50.4572 7.2331C49.8833 7.55393 49.412 8.03722 49.1 8.62503L48.9854 8.83063V8.514C48.9854 7.32768 48.4586 6.75199 47.3769 6.75199C46.2951 6.75199 45.7864 7.32768 45.7864 8.514V21.3559C45.7864 22.5587 46.3213 23.1426 47.4211 23.1426C48.5209 23.1426 49.0296 22.5587 49.0296 21.3559V16.8676L49.1422 17.0609C49.4743 17.62 49.9482 18.0768 50.5135 18.3829C51.1175 18.7153 51.794 18.8858 52.4799 18.8784C53.3603 18.8951 54.2243 18.6346 54.955 18.1321C55.6939 17.6025 56.2727 16.8717 56.6258 16.0226ZM53.2701 15.4264C53.059 15.7028 52.7876 15.9248 52.4776 16.0748C52.1675 16.2247 51.8275 16.2984 51.4846 16.2899C51.1379 16.3012 50.7934 16.2289 50.4791 16.0789C50.1647 15.9289 49.8893 15.7053 49.675 15.4264C49.2206 14.8568 48.9914 13.9645 48.9914 12.772C48.9914 11.5795 49.2206 10.716 49.675 10.1506C49.8921 9.87586 50.1684 9.65623 50.4824 9.50891C50.7963 9.36159 51.1394 9.29059 51.4846 9.30146C51.8261 9.29238 52.1651 9.36432 52.4749 9.51164C52.7846 9.65897 53.0568 9.87767 53.2701 10.1506C53.7245 10.7119 53.9537 11.5898 53.9537 12.772C53.9537 13.9543 53.7245 14.8568 53.2701 15.4264Z"
        fill={props.fill ?? 'white'}
      />
      <path
        d="M62.6397 16.9005V8.69082C62.6397 8.07402 62.499 7.58674 62.2195 7.25161C62.0698 7.08341 61.8852 6.95161 61.6793 6.86587C61.4733 6.78013 61.2512 6.74262 61.0292 6.7561C59.9294 6.7561 59.3945 7.3873 59.3945 8.68671V16.9005C59.3945 17.505 59.5353 17.982 59.8148 18.311C59.9675 18.4797 60.1549 18.6116 60.3635 18.6973C60.5721 18.7829 60.7967 18.8202 61.0211 18.8065C61.2456 18.8227 61.4708 18.7868 61.6797 18.7013C61.8887 18.6159 62.0759 18.4831 62.2275 18.313C62.5234 17.9066 62.6693 17.4063 62.6397 16.9005Z"
        fill={props.fill ?? 'white'}
      />
      <path
        d="M61.0292 1.16782C60.426 1.16782 59.9535 1.31997 59.6217 1.62015C59.4547 1.79065 59.3254 1.99577 59.2425 2.2217C59.1596 2.44762 59.1251 2.6891 59.1412 2.92984C59.1266 3.16704 59.1619 3.40468 59.2448 3.62671C59.3277 3.84875 59.4562 4.05001 59.6217 4.21691C59.9454 4.51709 60.4139 4.66924 61.0292 4.66924C62.2778 4.66924 62.885 4.09972 62.885 2.92984C62.885 1.75996 62.2778 1.16782 61.0292 1.16782Z"
        fill={props.fill ?? 'white'}
      />
      <path
        d="M75.15 11.7152C75.15 10.0128 74.7358 8.74017 73.9194 7.9301C73.1031 7.12002 71.8445 6.71293 70.1716 6.71293C69.6024 6.71844 69.0349 6.77832 68.4766 6.8918C67.8044 7.03159 67.1472 7.23817 66.5142 7.50861C66.3367 7.56507 66.173 7.6598 66.0342 7.78642C65.8955 7.91303 65.7849 8.06859 65.71 8.24261C65.5755 8.55963 65.5486 8.91355 65.6336 9.24801C65.6689 9.39989 65.734 9.54287 65.8249 9.66832C65.9158 9.79376 66.0306 9.89908 66.1624 9.9779C66.4258 10.1342 66.7656 10.1218 67.1556 9.93883C67.6557 9.71539 68.1754 9.54121 68.7078 9.41866C69.1436 9.31869 69.5883 9.26563 70.0349 9.26034C70.7607 9.26034 71.2855 9.4166 71.5931 9.72295C71.9008 10.0293 72.0576 10.5454 72.0576 11.267V11.7399H71.195C69.9501 11.7121 68.7058 11.8203 67.4834 12.0627C66.5645 12.2683 65.8929 12.6343 65.4888 13.1339C65.0847 13.6335 64.8856 14.3038 64.8856 15.1344C64.8779 15.8111 65.0646 16.4752 65.4224 17.0445C65.7858 17.6159 66.289 18.0802 66.8822 18.3912C67.5434 18.7333 68.2766 18.9041 69.0175 18.8887C69.6884 18.9088 70.3496 18.7202 70.9135 18.348C71.4234 17.9937 71.8094 17.4823 72.0154 16.8882L72.134 16.5613V17.0547C72.134 18.2555 72.6306 18.8414 73.656 18.8414C74.6815 18.8414 75.156 18.2555 75.156 17.0547L75.15 11.7152ZM72.0596 14.02C72.0733 14.3579 72.0217 14.6953 71.9079 15.0128C71.794 15.3303 71.6201 15.6217 71.3961 15.8705C71.1896 16.1003 70.938 16.2829 70.6576 16.4062C70.3773 16.5295 70.0746 16.5908 69.7695 16.586C69.2948 16.6165 68.8275 16.4548 68.4686 16.1357C68.319 15.9872 68.2014 15.8086 68.1232 15.611C68.0449 15.4135 68.0078 15.2015 68.0142 14.9884C68.0028 14.737 68.0691 14.4883 68.2038 14.2776C68.3384 14.0669 68.5344 13.9048 68.7641 13.8144C69.2447 13.5965 70.0711 13.4855 71.2191 13.4855H72.0596V14.02Z"
        fill={props.fill ?? 'white'}
      />
      <path
        d="M88.7761 8.52223C88.7761 7.3359 88.2554 6.76022 87.1857 6.76022C86.9665 6.74705 86.7471 6.78085 86.5414 6.85945C86.3357 6.93805 86.1484 7.05972 85.9914 7.21665C85.7139 7.51683 85.5732 7.95682 85.5732 8.53045V8.82858L85.4606 8.60858C85.1535 8.0233 84.6832 7.54455 84.1094 7.2331C83.4824 6.88417 82.7784 6.7051 82.0646 6.71293C81.1696 6.69267 80.2888 6.94377 79.5332 7.43459C78.7996 7.93527 78.2196 8.63813 77.8603 9.46183C77.4438 10.4195 77.2379 11.4589 77.2571 12.5068C77.2365 13.5635 77.4424 14.6119 77.8603 15.5785C78.2196 16.4016 78.7996 17.1038 79.5332 17.6037C80.2871 18.091 81.1649 18.3399 82.0566 18.3192C82.7799 18.3247 83.4923 18.1377 84.1235 17.7764C84.7157 17.4506 85.1974 16.9488 85.5048 16.3372L85.6174 16.1049V17.7106C85.6174 19.6309 84.6382 20.6034 82.704 20.6034C82.2178 20.5951 81.733 20.5469 81.2543 20.4595C80.6184 20.3369 80.002 20.1252 79.4226 19.8304C79.2821 19.7604 79.1285 19.7225 78.9722 19.7194C78.8248 19.7191 78.6801 19.7604 78.554 19.8386C78.2808 20.0143 78.0739 20.2795 77.9673 20.591C77.8606 20.9025 77.8605 21.2418 77.9669 21.5533C78.0369 21.7337 78.1438 21.8967 78.2805 22.0315C78.4171 22.1662 78.5803 22.2696 78.7591 22.3346C79.3785 22.6345 80.0335 22.8508 80.7074 22.9782C81.4614 23.1202 82.2269 23.1891 82.9935 23.1838C84.8715 23.1838 86.3191 22.6903 87.3003 21.7137C88.2815 20.7371 88.7761 19.2465 88.7761 17.2932V8.52223ZM84.9016 14.8754C84.6721 15.1459 84.3885 15.3628 84.0698 15.5115C83.7512 15.6601 83.4051 15.7371 83.0548 15.7371C82.7046 15.7371 82.3585 15.6601 82.0399 15.5115C81.7212 15.3628 81.4376 15.1459 81.2081 14.8754C80.7537 14.3058 80.5244 13.5102 80.5244 12.5068C80.5244 11.5035 80.7537 10.7119 81.2081 10.1506C81.4397 9.88365 81.7241 9.66996 82.0424 9.52362C82.3608 9.37728 82.7058 9.30161 83.0548 9.30161C83.4039 9.30161 83.7489 9.37728 84.0673 9.52362C84.3856 9.66996 84.67 9.88365 84.9016 10.1506C85.3621 10.7119 85.5953 11.5055 85.5953 12.5068C85.5953 13.5081 85.3621 14.3058 84.9016 14.8754Z"
        fill={props.fill ?? 'white'}
      />
      <path
        d="M102.491 8.52223C102.491 7.3359 101.97 6.76022 100.9 6.76022C100.681 6.74692 100.462 6.78066 100.256 6.85927C100.05 6.93788 99.8629 7.05961 99.706 7.21665C99.4285 7.51683 99.2878 7.95682 99.2878 8.53045V8.82858L99.1732 8.60858C98.8639 8.02485 98.3914 7.54878 97.816 7.24133C97.1897 6.88953 96.4857 6.70762 95.7711 6.71293C94.8761 6.69242 93.9953 6.94355 93.2397 7.43459C92.5061 7.93527 91.9261 8.63813 91.5669 9.46183C91.1503 10.4195 90.9444 11.4589 90.9637 12.5068C90.9431 13.5635 91.149 14.6119 91.5669 15.5785C91.9262 16.4016 92.5062 17.1038 93.2397 17.6037C93.9959 18.0926 94.8768 18.3415 95.7711 18.3192C96.4945 18.3245 97.2068 18.1374 97.8381 17.7764C98.4302 17.4511 98.9113 16.9491 99.2174 16.3372L99.332 16.1049V17.7106C99.332 19.6309 98.3508 20.6034 96.4166 20.6034C95.931 20.595 95.4469 20.5469 94.9689 20.4595C94.3323 20.337 93.7152 20.1253 93.1352 19.8304C92.9954 19.7604 92.8424 19.7225 92.6868 19.7194C92.5388 19.7197 92.3936 19.7609 92.2666 19.8386C91.9934 20.0143 91.7865 20.2795 91.6799 20.591C91.5732 20.9025 91.5731 21.2418 91.6795 21.5533C91.7499 21.7335 91.8569 21.8963 91.9935 22.031C92.13 22.1657 92.293 22.2692 92.4717 22.3346C93.0911 22.6345 93.746 22.8508 94.42 22.9782C95.174 23.1203 95.9394 23.1892 96.7061 23.1838C98.582 23.1838 100.032 22.6903 101.013 21.7137C101.994 20.7371 102.489 19.2465 102.489 17.2932L102.491 8.52223ZM98.6162 14.8754C98.3865 15.1459 98.1027 15.3629 97.7839 15.5116C97.4651 15.6602 97.1188 15.7372 96.7684 15.7372C96.4181 15.7372 96.0718 15.6602 95.753 15.5116C95.4342 15.3629 95.1503 15.1459 94.9206 14.8754C94.4683 14.3058 94.239 13.5102 94.239 12.5068C94.239 11.5035 94.4683 10.7119 94.9206 10.1506C95.1525 9.88361 95.437 9.66989 95.7555 9.52353C96.074 9.37717 96.4193 9.30149 96.7684 9.30149C97.1176 9.30149 97.4628 9.37717 97.7813 9.52353C98.0998 9.66989 98.3844 9.88361 98.6162 10.1506C99.0767 10.7119 99.3099 11.5055 99.3099 12.5068C99.3099 13.5081 99.0767 14.3058 98.6162 14.8754Z"
        fill={props.fill ?? 'white'}
      />
      <path
        d="M115.073 17.1575C115.18 16.8475 115.18 16.5094 115.073 16.1994C115.026 16.0561 114.95 15.9244 114.85 15.8129C114.75 15.7014 114.628 15.6125 114.492 15.5518C114.213 15.4243 113.863 15.4695 113.451 15.6834C113.036 15.907 112.591 16.0664 112.13 16.1563C111.726 16.2395 111.315 16.2842 110.903 16.2899C109.986 16.2899 109.259 16.0699 108.736 15.6402C108.213 15.2105 107.887 14.5114 107.767 13.5718V13.5019H114.402C115.065 13.5019 115.389 13.2244 115.389 12.6507C115.389 10.8353 114.921 9.37548 114 8.31252C113.079 7.24955 111.808 6.71293 110.248 6.71293C109.237 6.69291 108.24 6.95979 107.369 7.48394C106.536 7.99985 105.858 8.73853 105.406 9.62015C104.913 10.6009 104.666 11.6919 104.688 12.7947C104.66 13.9157 104.912 15.0257 105.422 16.0185C105.908 16.9201 106.644 17.6547 107.537 18.13C108.566 18.6529 109.703 18.9099 110.851 18.8784C111.461 18.8744 112.068 18.7985 112.661 18.6523C113.26 18.5113 113.837 18.2828 114.374 17.9738C114.698 17.8 114.947 17.509 115.073 17.1575ZM107.775 11.6844C107.879 10.8414 108.159 10.1753 108.605 9.70444C108.836 9.46581 109.112 9.27892 109.417 9.15572C109.722 9.03253 110.049 8.97573 110.377 8.98895C111.836 8.98895 112.62 9.89771 112.711 11.6885V11.7522H107.767L107.775 11.6844Z"
        fill={props.fill ?? 'white'}
      />
      <path
        d="M120.864 16.7319C120.885 16.2154 120.71 15.7105 120.377 15.3215C120.204 15.1364 119.994 14.9919 119.761 14.8978C119.528 14.8038 119.278 14.7624 119.028 14.7767C118.775 14.7642 118.522 14.8063 118.286 14.9002C118.05 14.994 117.836 15.1376 117.657 15.3215C117.313 15.7047 117.13 16.2118 117.15 16.7319C117.13 17.2607 117.312 17.7768 117.657 18.1712C117.834 18.3585 118.047 18.5051 118.284 18.6012C118.52 18.6973 118.774 18.7406 119.028 18.7283C119.279 18.7423 119.53 18.6998 119.763 18.6038C119.996 18.5079 120.206 18.3609 120.377 18.1732C120.71 17.772 120.884 17.2577 120.864 16.7319Z"
        fill={props.fill ?? 'white'}
      />
      <path
        d="M124.893 1.16782C124.29 1.16782 123.818 1.31997 123.486 1.62015C123.319 1.79054 123.189 1.9956 123.106 2.22152C123.023 2.44743 122.988 2.68896 123.003 2.92984C122.989 3.16718 123.025 3.40487 123.108 3.6269C123.191 3.84892 123.32 4.05011 123.486 4.21691C123.808 4.51709 124.278 4.66924 124.893 4.66924C126.144 4.66924 126.751 4.09972 126.751 2.92984C126.751 1.75996 126.144 1.16782 124.893 1.16782Z"
        fill={props.fill ?? 'white'}
      />
      <path
        d="M126.506 16.9005V8.69082C126.506 8.07402 126.365 7.58674 126.086 7.2516C125.936 7.08337 125.751 6.95156 125.544 6.86583C125.338 6.78009 125.116 6.74261 124.893 6.7561C123.794 6.7561 123.259 7.3873 123.259 8.68671V16.9005C123.226 17.4098 123.376 17.9139 123.679 18.3192C123.832 18.4878 124.019 18.6196 124.228 18.7053C124.436 18.791 124.661 18.8283 124.885 18.8147C125.11 18.8311 125.335 18.7952 125.544 18.7097C125.753 18.6243 125.94 18.4914 126.092 18.3212C126.369 17.982 126.506 17.4968 126.506 16.9005Z"
        fill={props.fill ?? 'white'}
      />
      <path
        d="M136.768 18.0149C136.891 17.6986 136.95 17.36 136.941 17.0198C136.941 16.5407 136.879 16.2241 136.756 16.0761C136.702 16.0049 136.632 15.9487 136.551 15.9128C136.47 15.8768 136.382 15.8623 136.294 15.8705C136.116 15.8898 135.94 15.9214 135.767 15.965C135.558 16.0157 135.344 16.0406 135.13 16.0391C134.474 16.0391 133.992 15.8622 133.69 15.5148C133.388 15.1673 133.24 14.6451 133.24 13.9604V9.54202H135.419C136.25 9.54202 136.652 9.13081 136.652 8.25906C136.652 7.3873 136.25 6.95143 135.419 6.95143H133.24V5.06605C133.24 3.86327 132.713 3.27936 131.631 3.27936C130.549 3.27936 129.996 3.86327 129.996 5.06605V6.95143H128.991C128.147 6.95143 127.734 7.37908 127.734 8.25906C127.734 9.13904 128.137 9.54202 128.991 9.54202H129.996V14.1146C129.996 15.6998 130.378 16.9026 131.132 17.6901C131.886 18.4775 133.037 18.8784 134.551 18.8784C134.836 18.873 135.12 18.8455 135.401 18.7962C135.667 18.7561 135.928 18.6871 136.179 18.5906C136.442 18.4857 136.654 18.2788 136.768 18.0149Z"
        fill={props.fill ?? 'white'}
      />
      <path
        d="M15.7011 17.4516L11.6416 21.8144C11.5906 21.8679 11.5533 21.9334 11.533 22.0051C11.5127 22.0768 11.5099 22.1526 11.525 22.2256C11.5366 22.2939 11.5642 22.3582 11.6056 22.413C11.6469 22.4678 11.7007 22.5115 11.7623 22.5402C12.428 22.8547 13.1647 22.9789 13.894 22.8994C14.6232 22.8199 15.3178 22.5398 15.9038 22.0889C16.4897 21.6379 16.9453 21.033 17.2218 20.3385C17.4984 19.6439 17.5858 18.8857 17.4745 18.1444C16.8649 17.9662 16.2716 17.7344 15.7011 17.4516Z"
        fill={props.fill ?? 'white'}
      />
      <path
        d="M20.8564 18.5371V23.3462C20.8587 23.4512 20.8863 23.554 20.9369 23.6455C20.9874 23.737 21.0592 23.8142 21.146 23.8705C21.2509 23.9405 21.3708 23.9834 21.4955 23.9956C21.6201 24.0077 21.7458 23.9888 21.8618 23.9404C22.5834 23.6256 23.1977 23.0992 23.6272 22.4278C24.0566 21.7563 24.2818 20.9698 24.2745 20.1676C24.2751 19.4202 24.0785 18.6864 23.7055 18.0437C22.7813 18.3361 21.8233 18.502 20.8564 18.5371Z"
        fill={props.fill ?? 'white'}
      />
      <path
        d="M24.9964 11.4109H24.5701C22.9543 11.4113 21.3502 11.1297 19.827 10.5783C19.3786 11.0923 18.9483 11.5898 18.512 12.0935L18.4235 12.1964C17.7379 12.9879 17.0905 13.7363 16.5838 14.3038C16.1817 14.7397 15.7936 15.1467 15.3995 15.5107C16.6779 16.353 18.1387 16.8619 19.6541 16.9931C21.4376 17.1531 23.229 16.7928 24.8205 15.954C26.412 15.1152 27.7383 13.8322 28.6457 12.2539C28.7254 12.1202 28.7636 11.965 28.7554 11.8087C28.7471 11.6523 28.6927 11.5022 28.5994 11.3781C28.505 11.2415 28.3728 11.1369 28.2199 11.0776C28.0669 11.0182 27.9002 11.0069 27.7409 11.045C26.8398 11.2576 25.9206 11.3801 24.9964 11.4109Z"
        fill={props.fill ?? 'white'}
      />
      <path
        d="M5.89522 2.48779C5.03325 4.57081 3.56634 6.33279 1.69296 7.53534C1.71307 8.04318 1.83572 8.97456 2.40272 9.61398C2.82623 10.0603 3.40334 10.3199 4.01124 10.3377L4.1178 10.3213C4.23844 10.3028 7.07547 9.80108 9.19067 5.07222C9.91016 3.39416 10.9817 1.89838 12.3293 0.690825C11.9091 0.538679 11.4929 0.398869 11.0927 0.279619C7.82142 -0.727834 6.43005 1.2295 5.89522 2.48779Z"
        fill={props.fill ?? 'white'}
      />
      <path
        d="M22.8973 4.84606C22.8068 4.04009 22.3624 2.13004 20.197 0.925213C19.5013 0.524149 18.7307 0.277823 17.9355 0.202356C17.1403 0.126889 16.3385 0.223981 15.5825 0.487279C13.4975 1.19661 11.6959 3.04498 10.5137 5.68903C8.54124 10.0951 5.85903 11.3493 4.78133 11.6844C4.7282 11.9269 4.72205 12.1776 4.76323 12.4225C4.78736 12.5582 5.39256 15.7779 8.6659 16.4071C11.1129 16.88 13.0893 15.9794 15.5021 13.2984C16.0027 12.7412 16.6522 11.9907 17.3378 11.1971L17.364 11.1663C17.8706 10.5824 18.5603 9.78258 19.2379 9.01157C19.0619 8.53606 19.0011 8.02403 19.0608 7.51928C19.1205 7.01453 19.2988 6.53207 19.5806 6.11318C19.8624 5.69429 20.2393 5.35143 20.6789 5.11393C21.1185 4.87643 21.6079 4.75136 22.1051 4.74942C22.3721 4.74666 22.6384 4.77914 22.8973 4.84606Z"
        fill={props.fill ?? 'white'}
      />
      <path
        d="M26.9809 6.05089C26.6253 6.05048 26.2775 6.15793 25.9817 6.35966C25.6858 6.56138 25.4551 6.84831 25.3187 7.18414C25.1824 7.51997 25.1465 7.88962 25.2156 8.24631C25.2848 8.603 25.4558 8.9307 25.7071 9.18797C25.9584 9.44524 26.2787 9.6205 26.6274 9.69159C26.9762 9.76268 27.3377 9.7264 27.6663 9.58734C27.9948 9.44828 28.2757 9.21269 28.4733 8.91038C28.6709 8.60806 28.7764 8.2526 28.7764 7.88897C28.7758 7.402 28.5866 6.93511 28.25 6.59058C27.9135 6.24604 27.4571 6.05197 26.9809 6.05089Z"
        fill={props.fill ?? 'white'}
      />
      <path
        d="M22.1051 6.05089C21.7495 6.05048 21.4017 6.15793 21.1059 6.35966C20.81 6.56138 20.5793 6.84831 20.4429 7.18414C20.3066 7.51997 20.2707 7.88962 20.3398 8.24631C20.409 8.603 20.58 8.9307 20.8313 9.18797C21.0826 9.44524 21.4029 9.6205 21.7516 9.69159C22.1004 9.76268 22.4619 9.7264 22.7905 9.58734C23.119 9.44828 23.3999 9.21269 23.5975 8.91038C23.7951 8.60806 23.9006 8.2526 23.9006 7.88897C23.9 7.402 23.7108 6.93511 23.3742 6.59058C23.0377 6.24604 22.5813 6.05197 22.1051 6.05089Z"
        fill={props.fill ?? 'white'}
      />
      <path
        d="M0.43229 2.22873C-0.195032 2.94423 0.014075 4.09149 0.132703 4.55204C0.277579 5.1702 0.570546 5.7418 0.985218 6.21537C2.56948 5.17361 3.81138 3.66946 4.5501 1.89771C3.82769 1.70201 3.08545 1.593 2.33838 1.57286C1.45772 1.56464 0.818334 1.78874 0.43229 2.22873Z"
        fill={props.fill ?? 'white'}
      />
    </g>
    <defs>
      <clipPath id="clip0_341_561">
        <rect fill={props.fill ?? 'white'} height="24" width="136.941" />
      </clipPath>
    </defs>
  </svg>
)

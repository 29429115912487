export enum AppMode {
  APP = 'app',
  KIOSK = 'kiosk',
  WIDGET = 'widget',
  WEBSITE = 'website',
}

export enum Country {
  FR = 'fr',
  IT = 'it',
}

import * as rd from '@devexperts/remote-data-ts'
import { BookingPeriod, DatePicker } from '@spiaggeit/spit-datepicker'
import { cn } from '@spiaggeit/spit-ui'
import { DateTime } from 'luxon'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'

import { CHOOSE_PRODUCT_PATH, TICKETS_PATH } from '../../app/router/paths'
import { KioskTimeoutDialog } from '../../components/Dialog/KioskTimeoutDialog'
import { SectorRulesDialog } from '../../components/SectorRulesDialog'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { useLockDates } from '../../hooks/useLockDates'
import { usePeriod } from '../../hooks/usePeriod'
import { AppMode } from '../../models/app'
import { appSlice } from '../../store/appSlice'
import {
  BookingAvailability,
  BookingFlow,
  bookingAvailabilitySlice,
} from '../../store/bookingAvailabilitySlice'
import {
  PeriodSliceState,
  insertPeriodSlice,
  periodAsUnixTimeSelector,
} from '../../store/insertPeriodSlice'
import { licenseSlice } from '../../store/licenseSlice'
import { sectorRulesSlice } from '../../store/sectorRulesSlice'
import {
  BOOKING_FLOW_QUERY_PARAM,
  getBookingFlowFromSearchParams,
} from '../../utils/bookingFlow'
import {
  HALF_DAY_QUERY_PARAM,
  getHalfDayValueFromBookingPeriod,
} from '../../utils/halfDay'
import { getLegacyUrl } from '../../utils/legacyUrl'
import { getShouldValidateForm } from '../../utils/sectorRulesUtils'

import { InsertPeriodAlerts } from './Alerts'

function getSearchString(
  searchParams: URLSearchParams,
  period: { start: number; end: number },
  bookingPeriod: BookingPeriod,
  bookingAvailability: BookingAvailability,
  appMode: AppMode
): string {
  searchParams.set('from', String(period.start))
  searchParams.set('to', String(period.end))
  searchParams.set(
    HALF_DAY_QUERY_PARAM,
    String(getHalfDayValueFromBookingPeriod(bookingPeriod))
  )

  if (bookingPeriod === BookingPeriod.ALL_SEASON) {
    searchParams.set('seasonal', '1')
  }

  if (
    appMode === AppMode.WEBSITE &&
    bookingAvailability === BookingAvailability.SPOTS
  ) {
    searchParams.set(BOOKING_FLOW_QUERY_PARAM, BookingFlow.SPOTS.toString())
  }

  if (
    appMode === AppMode.WEBSITE &&
    bookingAvailability === BookingAvailability.TICKETS
  ) {
    searchParams.set(BOOKING_FLOW_QUERY_PARAM, BookingFlow.TICKETS.toString())
  }

  return searchParams.toString()
}

function getNextPath(
  license: ReturnType<typeof licenseSlice.selectors.license>,
  bookingAvailability: BookingAvailability,
  bookingPeriod: ReturnType<typeof insertPeriodSlice.selectors.bookingPeriod>,
  searchString?: string
) {
  if (!license) return null

  if (bookingPeriod === BookingPeriod.ALL_SEASON) {
    return `${generatePath(CHOOSE_PRODUCT_PATH, { license: license.license })}?${searchString}`
  }

  switch (bookingAvailability) {
    case BookingAvailability.SPOTS:
      return `${getLegacyUrl()}/${license.license}/insertMap/?${searchString}`
    case BookingAvailability.TICKETS:
      return `${generatePath(TICKETS_PATH, { license: license.license })}?${searchString}`
    case BookingAvailability.SPOTS_AND_TICKETS:
      return `${generatePath(CHOOSE_PRODUCT_PATH, { license: license.license })}?${searchString}`
    default:
      return null
  }
}

export function InsertPeriod() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const lockDates = useLockDates()
  const { t, i18n } = useTranslation()

  usePeriod(lockDates)

  const period = useSelector(insertPeriodSlice.selectors.period)
  const periodAsUnixTime = useSelector(periodAsUnixTimeSelector)
  const license = useSelector(licenseSlice.selectors.license)
  const appMode = useSelector(appSlice.selectors.mode)
  const country = useSelector(appSlice.selectors.country)
  const sectorRules = useSelector(sectorRulesSlice.selectors.rules)
  const bookingAvailability = useAppSelector(
    bookingAvailabilitySlice.selectors.self
  )
  const areTicketsAvailable = useAppSelector(
    bookingAvailabilitySlice.selectors.areTicketsAvailable
  )
  const areSpotsAvailable = useAppSelector(
    bookingAvailabilitySlice.selectors.areSpotsAvailable
  )
  const bookingPeriod = useAppSelector(
    insertPeriodSlice.selectors.bookingPeriod
  )
  const bookingAvailabilityWithDefault = rd.getOrElse(() => ({
    type: BookingAvailability.NONE,
  }))(bookingAvailability)
  const seasonalStart = useAppSelector(licenseSlice.selectors.seasonalStart)
  const seasonalEnd = useAppSelector(licenseSlice.selectors.seasonalEnd)

  // local state
  const [showSectorRulesDialog, setShowSectorRulesDialog] =
    React.useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const [searchParams] = useSearchParams()
  const periodAsDate = useMemo(
    () =>
      period
        ? {
            end: DateTime.fromISO(period.end).setZone('utc')
              .toJSDate(),
            start: DateTime.fromISO(period.start).setZone('utc')
              .toJSDate(),
          }
        : null,
    [period]
  )

  useEffect(() => {
    if (!license) {
      return
    }

    dispatch(sectorRulesSlice.actions.load(license.license))
  }, [license, dispatch])

  useEffect(() => {
    if (
      !license ||
      !periodAsUnixTime ||
      !bookingPeriod ||
      bookingAvailabilityWithDefault.type === BookingAvailability.NONE
    ) {
      return
    }

    if (period?.start && period.end && isSubmitted) {
      const isPeriodSoldOut =
        (bookingAvailabilityWithDefault.type === BookingAvailability.SPOTS &&
          !areSpotsAvailable) ||
        (bookingAvailabilityWithDefault.type === BookingAvailability.TICKETS &&
          !areTicketsAvailable) ||
        (bookingAvailabilityWithDefault.type ===
          BookingAvailability.SPOTS_AND_TICKETS &&
          !areTicketsAvailable &&
          !areSpotsAvailable)

      if (!isPeriodSoldOut) {
        const searchString = getSearchString(
          searchParams,
          periodAsUnixTime,
          bookingPeriod,
          bookingAvailabilityWithDefault.type,
          appMode
        )

        const nextPath = getNextPath(
          license,
          bookingAvailabilityWithDefault.type,
          bookingPeriod,
          searchString
        )

        if (!nextPath) return

        if (appMode === AppMode.WIDGET) {
          window.open(nextPath, '_parent')
        } else if (nextPath.startsWith(getLegacyUrl())) {
          window.location.href = nextPath
        } else {
          navigate(nextPath)
        }
      }
    }
  }, [
    license,
    navigate,
    period,
    periodAsUnixTime,
    searchParams,
    bookingAvailabilityWithDefault.type,
    isSubmitted,
    bookingPeriod,
  ])

  if (!license || lockDates === null || sectorRules === null) {
    return null
  }

  const bookingStartPeriodSplit = license.bookingStartPeriod.split('-')
  const bookingStartDay = parseInt(bookingStartPeriodSplit[0])
  const bookingStartMonth = parseInt(bookingStartPeriodSplit[1])

  const bookingEndPeriodSplit = license.bookingEndPeriod.split('-')
  const bookingEndDay = parseInt(bookingEndPeriodSplit[0])
  const bookingEndMonth = parseInt(bookingEndPeriodSplit[1])

  function onChangeBookingPeriod(value: BookingPeriod): void {
    if (!license || !periodAsUnixTime || value === bookingPeriod) {
      return
    }

    dispatch(insertPeriodSlice.actions.setBookingPeriod(value))

    if (
      value === BookingPeriod.ALL_SEASON &&
      seasonalStart &&
      seasonalEnd &&
      periodAsDate?.start
    ) {
      const seasonalStartDateTime = DateTime.fromFormat(
        seasonalStart,
        'dd-LL-yyyy'
      )
      const fromDateTime = DateTime.fromJSDate(periodAsDate.start)

      handleSubmit(
        {
          end: DateTime.fromFormat(seasonalEnd, 'dd-LL-yyyy').toFormat(
            'yyyy-LL-dd'
          ),
          start:
            seasonalStartDateTime > fromDateTime
              ? seasonalStart
              : fromDateTime.toFormat('yyyy-LL-dd'),
        },
        value
      )
    }
  }

  async function handleSubmit(
    period: PeriodSliceState['period'],
    bookingPeriod: ReturnType<typeof insertPeriodSlice.selectors.bookingPeriod>
  ) {
    if (!license) {
      return
    }

    setIsLoading(true)

    if (
      period?.start !== period?.end &&
      bookingPeriod !== BookingPeriod.ALL_SEASON
    ) {
      dispatch(
        insertPeriodSlice.actions.setBookingPeriod(BookingPeriod.ALL_DAY)
      )
    }
    dispatch(insertPeriodSlice.actions.setPeriod(period))
    await dispatch(
      bookingAvailabilitySlice.actions.load({
        bookingFlow: getBookingFlowFromSearchParams(searchParams),
      })
    )

    try {
      const shouldValidate = await getShouldValidateForm({
        country,
        license,
        period,
        sectorRules,
      })
      if (typeof shouldValidate !== 'boolean') {
        // something bad happended
        // TODO handle error;
        return
      }

      if (shouldValidate) {
        setShowSectorRulesDialog(true)
      }
    } finally {
      setIsLoading(false)
      setIsSubmitted(true)
    }
  }

  return (
    <>
      <main
        className={cn(
          'flex flex-grow flex-col lg:items-center lg:justify-center',
          { 'px-4 pb-12 pt-8': appMode === AppMode.WIDGET }
        )}
      >
        <InsertPeriodAlerts isSubmitted={isSubmitted} />

        <div
          className={cn(
            {
              'flex grow flex-col lg:grow-0 lg:overflow-hidden lg:rounded lg:shadow-[0_2px_10px_0_rgba(0,0,0,.1)]':
                appMode !== AppMode.WIDGET,
            },
            {
              'overflow-hidden rounded shadow-[0_2px_10px_0_rgba(0,0,0,.1)]':
                appMode === AppMode.WIDGET,
            }
          )}
        >
          <DatePicker
            isLoading={isLoading}
            isWidget={appMode === AppMode.WIDGET}
            labels={{
              bookingPeriod: {
                afternoon: t('datepicker.bookingPeriod.afternoon'),
                all_day: t('datepicker.bookingPeriod.allDay'),
                all_season: t('datepicker.bookingPeriod.allSeason'),
                morning: t('datepicker.bookingPeriod.morning'),
              },
              confirmBtn: t('datepicker.confirm'),
              day: t('datepicker.day'),
              days: t('datepicker.days'),
            }}
            lang={i18n.language}
            lockDates={{
              maxDate: new Date(lockDates.maxDate),
              minDate: new Date(lockDates.minDate),
            }}
            lockPluginFilter={(date) => {
              if (Array.isArray(date)) {
                return false
              }

              const day = date.getDate()
              const month = date.getMonth() + 1

              if (month < bookingStartMonth || month > bookingEndMonth) {
                return true
              }

              if (month === bookingStartMonth && day < bookingStartDay) {
                return true
              }

              if (month === bookingEndMonth && day > bookingEndDay) {
                return true
              }

              return false
            }}
            onChangeBookingPeriod={(bookingPeriod) => {
              onChangeBookingPeriod(bookingPeriod)
            }}
            onConfirm={(period) => {
              const storePeriod: PeriodSliceState['period'] = {
                end: DateTime.fromJSDate(period.end).toFormat('yyyy-LL-dd'),
                start: DateTime.fromJSDate(period.start).toFormat('yyyy-LL-dd'),
              }

              handleSubmit(storePeriod, bookingPeriod)
            }}
            period={periodAsDate}
            selectedBookingPeriod={bookingPeriod}
            settings={{
              bookingHalfDay: license.bookingHalfDay,
              bookingSeasonal: license.bookingSeasonal,
            }}
          />
        </div>

        <SectorRulesDialog
          isOpen={showSectorRulesDialog}
          onConfirm={(unavailableSectors, taxCode) => {
            setShowSectorRulesDialog(false)

            if (!periodAsUnixTime || !bookingPeriod) {
              return
            }

            const newSearchParams = new URLSearchParams(searchParams.toString())

            newSearchParams.set(
              'unavailableSectors',
              unavailableSectors.join('-')
            )

            newSearchParams.set('taxCode', taxCode)

            const searchString = getSearchString(
              newSearchParams,
              periodAsUnixTime,
              bookingPeriod,
              bookingAvailabilityWithDefault.type,
              appMode
            )

            navigate(
              `${generatePath(TICKETS_PATH, { license: license.license })}?${searchString}`
            )
          }}
          sectorRules={sectorRules}
          setIsOpen={setShowSectorRulesDialog}
        />
      </main>

      <KioskTimeoutDialog timeout={60000} />
    </>
  )
}

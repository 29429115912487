import { createSelector, PayloadAction } from '@reduxjs/toolkit'
import { DateTime } from 'luxon'

import { httpClient } from '../lib/http/HttpClient'
import { Service } from '../models/service'

import { appSlice } from './appSlice'
import { RootState } from './configureStore'
import { createAppSlice } from './createAppSlice'
import { insertPeriodSlice } from './insertPeriodSlice'
import { licenseSlice } from './licenseSlice'
import { servicesSlice } from './servicesSlice'

interface QuoteResponse {
  result: {
    totalPrice: number
    fees: number
  }
}

interface CartSliceState {
  items: Record<
    number,
    {
      quantity: number
      id: number
    }
  >
  total: number | null
  fees: number
}

const initialState: CartSliceState = {
  fees: 0,
  items: {},
  total: null,
}

export const cartSlice = createAppSlice({
  initialState: initialState satisfies CartSliceState as CartSliceState,
  name: 'cart',
  reducers: (create) => ({
    add: create.reducer(
      (
        state,
        action: PayloadAction<{
          id: number
        }>
      ) => {
        const item = state.items[action.payload.id]
        if (item) {
          state.items[action.payload.id].quantity += 1
        } else {
          state.items[action.payload.id] = {
            id: action.payload.id,
            quantity: 1,
          }
        }
      }
    ),
    decrease: create.reducer((state, action: PayloadAction<{ id: number }>) => {
      const item = state.items[action.payload.id]
      if (item) {
        state.items[action.payload.id].quantity = Math.max(0, item.quantity - 1)
      }
    }),
    quote: create.asyncThunk<
      { fees: number; totalPrice: number },
      void,
      { rejectValue: string }
    >(
      async (_, thunkApi): Promise<{ fees: number; totalPrice: number }> => {
        const rootState = thunkApi.getState() as RootState
        const license = licenseSlice.selectors.license(rootState)
        const period = insertPeriodSlice.selectors.period(rootState)
        const channel = appSlice.selectors.channel(rootState)
        const country = appSlice.selectors.country(rootState)
        if (!license) {
          throw thunkApi.rejectWithValue('License not found')
        }

        if (!period) {
          throw thunkApi.rejectWithValue('Period not found')
        }

        const res = await httpClient.fetch<QuoteResponse>(
          `beaches/${license.license}/carts/quote`,
          country,
          {
            body: JSON.stringify({
              channel,
              feesTableid: '',
              reservations: [
                {
                  beds: 0,
                  chairs: 0,
                  deskChairs: 0,
                  endDate: DateTime.fromFormat(period.end, 'yyyy-LL-dd', {
                    zone: 'utc',
                  }).toSeconds(),
                  feesTableid: '',
                  halfDay: 0,
                  maxiBeds: 0,
                  notes: '',
                  seasonal: 0,
                  sector: 0,
                  services: Object.values(rootState.cart.items).reduce(
                    (acc, item) => ({
                      ...acc,
                      [`id${item.id}`]: item.quantity,
                    }),
                    {}
                  ),
                  spotName: null,
                  spotType: null,
                  startDate: DateTime.fromFormat(period.start, 'yyyy-LL-dd', {
                    zone: 'utc',
                  }).toSeconds(),
                },
              ],
            }),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'POST',
          }
        )

        if (res.status === 'error') {
          throw thunkApi.rejectWithValue(res.error)
        }

        return res.data.result
      },
      {
        fulfilled: (state, action) => {
          state.total = action.payload.totalPrice
          state.fees = action.payload.fees
        },
        options: {
          condition: (_, thunkApi) => {
            const state = thunkApi.getState() as RootState
            if (Object.keys(state.cart.items).length === 0) {
              return false
            }
          },
        },
      }
    ),
    set: create.reducer(
      (state, action: PayloadAction<{ id: number; quantity: number }>) => {
        state.items[action.payload.id] = action.payload
      }
    ),
  }),
  selectors: {
    fees: (state) => {
      const totalQuantity = cartSlice.selectors.totalQuantity({ cart: state })
      if (totalQuantity === 0) return 0
      return state.fees
    },
    itemById: (state, id: number) => state.items[id],
    items: (state) => state.items,
    total: (state) => state.total,
    totalQuantity: (state) =>
      Object.values(state.items).reduce((acc, item) => acc + item.quantity, 0),
  },
})

export const cartPayloadSelector = createSelector(
  [
    appSlice.selectors.channel,
    licenseSlice.selectors.license,
    insertPeriodSlice.selectors.period,
    cartSlice.selectors.items,
  ],
  (channel, license, period, items) => {
    if (!channel || !license || !period) return null
    return {
      beachLicense: license.license,
      channel,
      registrations: {
        _ticket_00: {
          beds: 0,
          chairs: 0,
          deckchairs: 0,
          end: DateTime.fromFormat(period.end, 'yyyy-LL-dd', {
            zone: 'utc',
          }).toSeconds(),
          halfDay: 0,
          maxibeds: 0,
          note: '',
          seasonal: 0,
          sector: 0,
          services: Object.values(items).reduce(
            (acc, item) => ({
              ...acc,
              [`id${item.id}`]: item.quantity,
            }),
            {}
          ),
          spotName: '',
          spotType: 'ticket',
          start: DateTime.fromFormat(period.start, 'yyyy-LL-dd', {
            zone: 'utc',
          }).toSeconds(),
        },
      },
    }
  }
)

export const cartTotalSelector = createSelector(
  [
    cartSlice.selectors.totalQuantity,
    cartSlice.selectors.items,
    servicesSlice.selectors.self,
    cartSlice.selectors.total,
  ],
  (totalQuantity, cartItems, services, total) => {
    if (services.status !== 'success' || totalQuantity === 0) return 0

    if (total) return total

    return Object.keys(cartItems).reduce((acc, id) => {
      const item = cartItems[Number(id)]
      const service = services.data.serviceGroups
        .flatMap((group) => group.services)
        .find((service) => service.serviceId === item.id)
      if (!service) return acc

      return acc + service.price * item.quantity
    }, 0)
  }
)

type ServiceWithQuantity = Service & { quantity: number }
export const cartItemsWithDetailsSelector = createSelector(
  [cartSlice.selectors.items, servicesSlice.selectors.self],
  (cartItems, services) => {
    if (services.status !== 'success') return []

    return Object.keys(cartItems)
      .map((id): ServiceWithQuantity | null => {
        const service = services.data.serviceGroups
          .flatMap((group) => group.services)
          .find((service) => service.serviceId === Number(id))

        if (!service) return null

        return {
          ...service,
          quantity: cartItems[Number(id)].quantity,
        }
      })
      .filter((item) => item && item.quantity > 0) as ServiceWithQuantity[]
  }
)

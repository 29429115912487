import { DateTime } from 'luxon'

import { Printer } from '../globals'
import {
  ReservationType,
  SpotType,
  Reservation,
  PrintArticle,
  ReservationService,
  Service,
} from '../models/cart'

export interface FiscalReceipt {
  articles: PrintArticle[]
  discount: number
  paymentMethod: 'bancomat'
  printMode: 'fis'
  codeType?: 'QR'
  codeText?: string
  timestamp: number
}

export interface QrCodeReceipt {
  articles: PrintArticle[]
  codeType: 'QR'
  codeText: string
  printMode: 'nfis'
  timestamp: number
}

function getSpotReceiptDescription(data: {
  areaBedsName: string
  spotName: string
  spotType: SpotType
  reservationType: ReservationType
}): string {
  switch (data.spotType) {
    case SpotType.CABIN:
      return `Cab ${data.spotName}`
    case SpotType.PARKING:
      return `Park ${data.spotName}`
    case SpotType.BEDS:
      return `Area ${data.areaBedsName}`
    case SpotType.BED:
      return `Lett ${data.spotName}`
    case SpotType.GAZEBO:
      return `Gazebo ${data.spotName}`
    case null:
      return 'Ticket'
    default:
      if (data.reservationType === ReservationType.ADDITION) {
        return ''
      }
  }
  return `Omb ${data.spotName}`
}

export function getArticleDescription(reservation: Reservation): string {
  const description = [] as Array<string>
  const spotName = reservation.spot_name

  // detail of the reservation sector
  const areaBedsName = reservation.sector?.name ?? spotName

  // Standard reservation description
  const spotDescription = getSpotReceiptDescription({
    areaBedsName,
    reservationType: reservation.type,
    spotName: reservation.spot_name,
    spotType: reservation.spot_type,
  })
  if (spotDescription.length > 0) {
    description.push(spotDescription)
  }

  if (reservation.maxi_beds) {
    description.push(`Max ${reservation.maxi_beds}`)
  }
  if (reservation.beds) {
    description.push(`Lett ${reservation.beds}`)
  }
  if (reservation.chairs) {
    description.push(`Sedie ${reservation.chairs}`)
  }
  if (reservation.deck_chairs) {
    description.push(`Sdraio ${reservation?.deck_chairs}`)
  }
  let string = description.join(' + ')
  const startDate = reservation.start_date
  const endDate = reservation.end_date
  string += ` ${DateTime.fromSeconds(startDate).toFormat('dd/MM')}`
  if (startDate !== endDate) {
    string += ` - ${DateTime.fromSeconds(endDate).toFormat('dd/MM')}`
  }
  return `Serv. ${string}`
}

const PRINTER_DOMAIN = 'https://www.anm22.it'

export function printReceipt(
  printer: Printer,
  reservations: Reservation[],
  services: Service[],
  onSuccess: (
    data: {
      total: number
      date: string
      printerId: string
      paymentMethod: string
      receiptNumber: string
    } | null,
    shouldLog: boolean
  ) => void,
  onError: (
    error: { status: number; responseText: string },
    shouldLog: boolean
  ) => void
) {
  const fiscalReceipt: FiscalReceipt = {
    articles: [] as PrintArticle[],
    discount: 0,
    paymentMethod: 'bancomat',
    printMode: 'fis',
    timestamp: Date.now(),
  }
  const qrCodeReceipts: QrCodeReceipt[] = []

  reservations.forEach((reservation: Reservation) => {
    const mainArticle: PrintArticle = {
      description: getArticleDescription(reservation),
      price: reservation.forced_beach,
      quantity: 1,
      vat: 22,
    }
    const qrCodeReceipt: QrCodeReceipt = {
      articles: [] as PrintArticle[],
      codeText: reservation.qrCode,
      codeType: 'QR',
      printMode: 'nfis',
      timestamp: Date.now(),
    }

    fiscalReceipt.articles.push(mainArticle)
    // discount is an extra service with a static id
    const discount = reservation.services.find((rs) => rs.service_id === 1613)
    if (discount) {
      fiscalReceipt.discount = Math.abs(discount.price)
    }

    if (reservations.length === 1) {
      fiscalReceipt.codeText = reservation.qrCode
      fiscalReceipt.codeType = 'QR'
    } else {
      qrCodeReceipt.articles.push(mainArticle)
    }

    reservation.services
      .filter(
        (element: ReservationService) =>
          element.bought - (element.deleted ?? 0) > 0 && element.price > 0
      )
      .forEach((rs: ReservationService) => {
        const serviceArticle = {} as PrintArticle
        const service = services.find(
          (item: Service) => item.id === rs.service_id
        )
        if (!service) return
        if (rs.service_id === 512) {
          serviceArticle.description = `${service?.name}`
          serviceArticle.quantity = 1
          serviceArticle.price = rs.price
          serviceArticle.vat = 22
        } else {
          serviceArticle.description = `Serv. ext. ${service?.name}`
          serviceArticle.quantity = rs.bought - (rs.deleted ?? 0)
          serviceArticle.price =
            Math.round((rs.price / serviceArticle.quantity) * 100) / 100
          serviceArticle.vat = service.vat ?? 22
        }
        fiscalReceipt.articles.push(serviceArticle)

        if (reservations.length > 1) {
          qrCodeReceipt.articles.push(serviceArticle)
        }
      })

    if (reservations.length > 1) {
      qrCodeReceipts.push(qrCodeReceipt)
    }
  })

  window.createPrinter(printer, PRINTER_DOMAIN)
  const printerKey = `world_printer_${printer.idPrinter}`

  // check if the printers are ready while retrying
  let retries = 0

  const interval = setInterval(() => {
    if (window[printerKey]) {
      clearInterval(interval)

      // eslint-disable-next-line no-console
      console.log('Printing fiscal receipt...', fiscalReceipt)
      window[printerKey].print(
        fiscalReceipt,
        (data) =>
          onSuccess(
            {
              date: DateTime.fromMillis(fiscalReceipt.timestamp).toFormat(
                'dd/MM/yyyy HH:mm'
              ),
              paymentMethod: fiscalReceipt.paymentMethod,
              printerId: String(printer.idPrinter),
              receiptNumber: data?.receiptNumber ?? '',
              total: Number(data?.receiptAmount ?? 0),
            },
            true
          ),
        (data) => onError(data, true)
      )

      qrCodeReceipts.forEach((qrCodeReceipt: QrCodeReceipt) => {
        // eslint-disable-next-line no-console
        console.log('Printing qrCode receipt...', qrCodeReceipt)
        window[printerKey].print(
          qrCodeReceipt,
          () => onSuccess(null, false),
          (data) => onError(data, false)
        )
      })
    } else {
      retries += 1
      if (retries > 10) {
        clearInterval(interval)
        onError({ responseText: 'Printer not found', status: 500 }, false)
      }
    }
  }, 1000)
}

import { cn } from '@spiaggeit/spit-ui'

interface Props {
  children: React.ReactNode
  isActive: boolean
  onClick: VoidFunction
}
export const Item = (props: Props) => {
  return (
    <li className={cn('rounded-sm', { 'bg-gray-25': props.isActive })}>
      <button
        className="flex items-center space-x-2 p-2 text-sm"
        onClick={props.onClick}
      >
        {props.children}
      </button>
    </li>
  )
}

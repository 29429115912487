import { Button } from '@spiaggeit/spit-ui'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Card } from '../../components/Card'

interface Props {
  areSpotsAvailable: boolean
  areTicketsAvailable: boolean
  bookSpotsLink: string
  bookTicketsLink: string
  disabledTicketsLink: string
}

export const ChooseProductDefault = (props: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <main className="min-h-full flex-grow bg-[#fafafa] px-4 pb-28 pt-8 lg:pb-8">
      <div className="flex flex-col items-center justify-center space-y-4 md:mt-20 md:flex-row md:space-x-8 md:space-y-0">
        <Card
          action={
            <Button
              disabled={!props.areSpotsAvailable}
              fullWidth
              onClick={() => {
                window.location.href = props.bookSpotsLink
              }}
              type="button"
            >
              {t('chooseProduct.spot.book')}
            </Button>
          }
          isSoldOut={!props.areSpotsAvailable}
          list={[
            t('chooseProduct.spot.listItem00'),
            t('chooseProduct.spot.listItem01'),
          ]}
          title={t('chooseProduct.spot.title')}
        />

        <div className="hidden md:block">{t('chooseProduct.or')}</div>

        <Card
          action={
            <Button
              color="primary"
              disabled={!props.areTicketsAvailable}
              fullWidth
              onClick={() => navigate(props.bookTicketsLink)}
              type="button"
              variant="outline"
            >
              {t('chooseProduct.ticket.book')}
            </Button>
          }
          isSoldOut={!props.areTicketsAvailable}
          list={[
            t('chooseProduct.ticket.listItem00'),
            t('chooseProduct.ticket.listItem01'),
          ]}
          title={t('chooseProduct.ticket.title')}
        />
      </div>
    </main>
  )
}

import { cn } from '@spiaggeit/spit-ui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Cart } from '../../assets/icons/Cart'
import { ChevronUp } from '../../assets/icons/ChevronUp'
import { Info } from '../../assets/icons/Info'
import { useAppSelector } from '../../hooks/store'
import { cartSlice, cartTotalSelector } from '../../store/cartSlice'
import { priceFormatter } from '../../utils/price'
import { servicesWithCartQuantitySelector } from '../../utils/services'

import { SubmitButton } from './SubmitButton'

interface Props {
  openFeesDialog: VoidFunction
}

export const MobileDrawer = (props: Props) => {
  const { t } = useTranslation()
  const cartTotal = useAppSelector(cartTotalSelector)
  const servicesWithCartQuantity = useAppSelector(
    servicesWithCartQuantitySelector
  )
  const totalQuantity = useAppSelector(cartSlice.selectors.totalQuantity)
  const fees = useAppSelector(cartSlice.selectors.fees)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {isOpen
        ? (
            <div
              className="fixed left-0 top-0 z-20 h-full w-full bg-black bg-opacity-50 md:hidden"
              onClick={() => setIsOpen(false)}
            ></div>
          )
        : null}

      <div className="fixed bottom-0 left-0 z-30 w-full rounded-tl-lg rounded-tr-lg bg-white shadow-[rgba(0,0,0,0.1)_0px_-4px_8px_-3px] md:hidden">
        <div
          className="flex cursor-pointer justify-center pt-2"
          onClick={() => setIsOpen((state) => !state)}
        >
          <ChevronUp className={isOpen ? 'rotate-180' : ''} />
        </div>

        <div>
          <div
            className={cn({
              hidden: !isOpen,
            })}
          >
            <div className="space-y-2 divide-y divide-gray-100 px-4">
              {servicesWithCartQuantity?.map((group) => (
                <div className="py-4" key={group.serviceGroupId}>
                  <div className="mb-2 font-bold">{group.name}</div>

                  <div className="space-y-2">
                    {group.services.map((service) => (
                      <div
                        className="flex justify-between text-sm"
                        key={service.serviceId}
                      >
                        <div className="flex items-center space-x-2">
                          <div>
                            <img
                              alt={service.name}
                              className="h-4 w-4"
                              src={`/icons/${service.icon}`}
                            />
                          </div>

                          <div>
                            {service.quantity} x {service.name}
                          </div>
                        </div>

                        <div className="font-medium text-secondary">
                          {priceFormatter.format(
                            service.price * service.quantity
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>

            <div className="space-y-2 border-t border-gray-100 p-4 text-sm font-medium text-secondary">
              <div className="flex justify-between">
                <div>Ticket</div>
                <div>{priceFormatter.format(cartTotal - fees)}</div>
              </div>

              <div className="flex justify-between">
                <div
                  className="flex cursor-pointer space-x-1"
                  onClick={props.openFeesDialog}
                >
                  <div className="text-sm text-secondary underline">
                    Costi di servizio
                  </div>{' '}
                  <Info />
                </div>

                <div>{priceFormatter.format(fees)}</div>
              </div>
            </div>
          </div>

          <div
            className={cn('flex items-center space-x-4 p-4', {
              'border-t border-gray-100': isOpen,
            })}
          >
            <div className="relative">
              <button
                onClick={() => setIsOpen((state) => !state)}
                type="button"
              >
                <Cart />
              </button>

              <div className="absolute left-1/2 top-0 flex h-4 w-4 -translate-y-1/2 items-center justify-center rounded-circle bg-yellow-200 text-xs text-white">
                {totalQuantity}
              </div>
            </div>

            <div>
              <div className="font-bold">
                {priceFormatter.format(cartTotal)}
              </div>

              <div
                className="flex cursor-pointer space-x-1"
                onClick={props.openFeesDialog}
              >
                <div className="text-sm text-secondary underline">
                  {t('tickets.summary.includedFees')}
                </div>{' '}
                <Info />
              </div>
            </div>

            <SubmitButton isLoading={false} />
          </div>
        </div>
      </div>
    </>
  )
}

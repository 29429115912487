import { useTranslation } from 'react-i18next'

import { Flags } from '../../assets/icons/flags'

const CLASS_NAME = 'h-10 w-10'

export const Current = () => {
  const { i18n } = useTranslation()

  switch (i18n.language) {
    case 'de-DE':
    case 'de':
      return <Flags.DE className={CLASS_NAME} />
    case 'en-US':
    case 'en':
      return <Flags.EN className={CLASS_NAME} />
    case 'es-ES':
    case 'es':
      return <Flags.ES className={CLASS_NAME} />
    case 'fr-FR':
    case 'fr':
      return <Flags.FR className={CLASS_NAME} />
    case 'it-IT':
    case 'it':
      return <Flags.IT className={CLASS_NAME} />
    case 'pt-PT':
    case 'pt':
      return <Flags.PT className={CLASS_NAME} />
  }
}
